import sortBy from 'lodash/sortBy';
import { EntityType, HierarchyNode } from 'types';
import { CustomSelectOption } from '../Select/CustomSelect';
import { SelectOption } from '../Select';

export function flattenHierarchyTree(
  tree: HierarchyNode[],
  entityTypes: EntityType[],
): HierarchyNode[] {
  const filterNode = (node: HierarchyNode) =>
    entityTypes.includes(node.type as EntityType) ? [node] : [];

  return sortBy(tree, 'sequence').flatMap(node => {
    if (node.hasChildren) {
      return [
        ...filterNode(node),
        ...flattenHierarchyTree(node.children, entityTypes),
      ];
    }

    return filterNode(node);
  });
}

export function mapHierarchyNodeToCustomSelectOption(
  node: HierarchyNode,
  index: number,
  nodes: HierarchyNode[],
): CustomSelectOption {
  return {
    value: node.id,
    label: node.name || node.id,
    depth: (node.depth || 0) - (nodes[0]?.depth || 0),
  };
}

export function mapHierarchyNodeToSelectOption(
  node: HierarchyNode,
  index: number,
  nodes: HierarchyNode[],
): SelectOption {
  return {
    id: node.id,
    content: node.name,
    depth: (node.depth || 0) - (nodes[0]?.depth || 0),
    parentId: node.parentId,
  };
}

function findParentLocation(node: HierarchyNode, nodes: HierarchyNode[]) {
  const parentNode = nodes.find(item => item.id === node.parentId);

  if (parentNode?.type === EntityType.LOCATION) {
    return parentNode;
  }

  return parentNode ? findParentLocation(parentNode, nodes) : undefined;
}

export function mapNodeEntityTypeIdPrefix(
  node: HierarchyNode,
  index: number,
  nodes: HierarchyNode[],
) {
  if (node.type === EntityType.LOCATION) {
    return node;
  }

  const parentLocation = findParentLocation(node, nodes);
  return {
    ...node,
    id: `${parentLocation.id}#${node.id}`,
  };
}
