import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '@eriksdigital/atomic-ui/components';
import {
  LoadingIcon,
  CheckIcon,
  CrossIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import { mediaQuery } from 'utils/mediaQuery';
import { ProcessStatus } from 'types';

type Props = {
  status: ProcessStatus;
};

function ProcessStatusIcon({ status }: Props) {
  if (status === 'success') {
    return (
      <SuccessWrapper>
        <Icon as={CheckIcon} />
      </SuccessWrapper>
    );
  }

  if (status === 'failed') {
    return (
      <FailedWrapper>
        <Icon as={CrossIcon} />
      </FailedWrapper>
    );
  }

  return (
    <Wrapper>
      <Icon as={LoadingIcon} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text.secondary};

  svg {
    width: 16px;
    height: 16px;
  }

  ${mediaQuery.phoneLarge} {
    margin: 0 auto;
  }
`;

const SuccessWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.text.proceed}29;
  color: ${({ theme }) => theme.colors.text.proceed};

  svg {
    width: 12px;
    height: 12px;
  }
`;

const FailedWrapper = styled(SuccessWrapper)`
  background-color: ${({ theme }) => theme.colors.default.red}29;
  color: ${({ theme }) => theme.colors.default.red};
`;

export default ProcessStatusIcon;
