import React, { useEffect } from 'react';
import { translationString } from 'locales/translation';
import useEquipmentTypesAll from 'app/containers/Equipments/hooks/useEquipmentTypesAll';
import useMetadata from 'app/containers/Equipments/hooks/useMetadata';
import { Select } from 'app/elements/Fields/withValidation';
import { LoadingIndicator } from '../LoadingIndicator';

type Props = React.SelectHTMLAttributes<HTMLSelectElement>;

function EquipmentTypeSelect({
  id = 'miTypeId',
  name = 'miTypeId',
  placeholder = translationString('SelectPlaceholder'),
  ...rest
}: Props) {
  const { isLoadingMetadata, fetchMetadata } = useMetadata();
  const { allEquipmentTypes } = useEquipmentTypesAll();

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  if (isLoadingMetadata) {
    return <LoadingIndicator />;
  }

  return (
    <Select
      id={id}
      name={name}
      placeholder={placeholder}
      {...rest}
      options={allEquipmentTypes.map(miType => ({
        value: miType.maintainableItemTypeId,
        label: miType.translation,
      }))}
    ></Select>
  );
}

export default EquipmentTypeSelect;
