import React, { FC, useState } from 'react';
import styled from 'styled-components';

type InputElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

export default function withValidation<
  T extends React.InputHTMLAttributes<InputElement>
>(Component: FC<T>): FC<T> {
  return (props: T) => {
    const ariaErrorMessage = props['aria-errormessage'];
    const [validationMessage, setValidationMessage] = useState('');
    const validationMessageId = `validationMessage_${props.name}`;

    const handleInvalid = (e: React.InvalidEvent<InputElement>) => {
      setValidationMessage(e.currentTarget.validationMessage);
    };

    const handleChange = (e: React.ChangeEvent<InputElement>) => {
      setValidationMessage(e.currentTarget.validationMessage);
      props.onChange?.(e);
    };

    const isInvalid = !!validationMessage || !!ariaErrorMessage;

    return (
      <Wrapper>
        <Component
          {...props}
          onInvalid={handleInvalid}
          onChange={handleChange}
          aria-invalid={isInvalid}
          aria-errormessage={isInvalid ? validationMessageId : undefined}
        />
        {isInvalid && (
          <ValidationMessage id={validationMessageId}>
            {validationMessage || ariaErrorMessage}
          </ValidationMessage>
        )}
      </Wrapper>
    );
  };
}

const Wrapper = styled.div`
  position: relative;

  input[aria-invalid='true'],
  textarea[aria-invalid='true'],
  select[aria-invalid='true'],
  fieldset:has(input[aria-invalid='true']) {
    outline: 1px solid ${({ theme }) => theme.colors.default.red};
  }
`;

const ValidationMessage = styled.span`
  position: absolute;
  top: 100%;
  left: 0;
  color: ${({ theme }) => theme.colors.default.red};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
`;
