import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { HierarchyNode, MaintainableItemLocation } from 'types';
import {
  AssetSortDirection,
  AssetSortField,
  AssetStatusFilter,
  ContainerState,
} from './types';

// The initial state of the AssetsDashboard container
export const initialState: ContainerState = {
  customerHierarchies: [],
  customerHierarchiesLoading: false,
  customerHierarchiesError: '',
  hierarchy: null,
  hierarchyLoading: false,
  hierarchyError: '',
  maintainableItems: [],
  assetFilterSearch: '',
  assetFilterStatus: ['all'],
  assetFilterIds: [],
  assetSortField: 'name',
  assetSortDirection: -1,
  activeTreeNodeId: '',
};

const assetsNavigatorSlice = createSlice({
  name: 'assetsDashboard',
  initialState,
  reducers: {
    getCustomerHierarchy(state, action: PayloadAction<string>) {
      return { ...state, customerHierarchiesLoading: true };
    },
    getCustomerHierarchySuccess(state, action: PayloadAction<HierarchyNode>) {
      const returnObject = {
        ...state,
        customerHierarchiesLoading: initialState.customerHierarchiesLoading,
        customerHierarchiesError: initialState.customerHierarchiesError,
      };
      if (state.customerHierarchies.find(ch => ch.id === action.payload.id)) {
        return {
          ...returnObject,
          customerHierarchies: state.customerHierarchies.map(ch => {
            if (ch.id === action.payload.id)
              return { ...ch, ...action.payload };
            return ch;
          }),
        };
      } else {
        return {
          ...returnObject,
          customerHierarchies: [...state.customerHierarchies, action.payload],
        };
      }
    },
    getCustomerHierarchyFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        customerHierarchiesLoading: initialState.customerHierarchiesLoading,
        customerHierarchies: initialState.customerHierarchies,
        customerHierarchiesError: action.payload,
      };
    },
    getHierarchy(state, action: PayloadAction<{ locationId: string }>) {
      return state;
    },
    refreshHierarchy(
      state,
      action: PayloadAction<{ locationId: string; noRefresh?: boolean }>,
    ) {
      return state;
    },
    resetHierarchy(
      state,
      action: PayloadAction<{ locationId: string; noRefresh?: boolean }>,
    ) {
      return state;
    },
    getHierarchySuccess(state, action: PayloadAction<HierarchyNode>) {
      return {
        ...state,
        hierarchyLoading: initialState.hierarchyLoading,
        hierarchy: action.payload,
        hierarchyError: initialState.hierarchyError,
      };
    },
    getHierarchyFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        hierarchyLoading: initialState.hierarchyLoading,
        hierarchy: initialState.hierarchy,
        hierarchyError: action.payload,
      };
    },

    reorderHierarchy(
      state,
      action: PayloadAction<{
        customerId: string;
        parentId: string;
        childIds: string[];
      }>,
    ) {
      return state;
    },

    setMaintainableItems(
      state,
      action: PayloadAction<MaintainableItemLocation[]>,
    ) {
      return {
        ...state,
        maintainableItems: action.payload,
      };
    },
    deleteSpace(
      state,
      action: PayloadAction<{
        spaceId: string;
        customerId: string;
      }>,
    ) {
      return {
        ...state,
        customerHierarchiesLoading: true,
      };
    },
    deleteAsset(
      state,
      action: PayloadAction<{
        assetId: string;
        customerId: string;
      }>,
    ) {
      return {
        ...state,
        customerHierarchiesLoading: true,
      };
    },
    deleteSubUnit(
      state,
      action: PayloadAction<{
        subUnitId: string;
        customerId: string;
      }>,
    ) {
      return {
        ...state,
        customerHierarchiesLoading: true,
      };
    },
    deleteMaintainableItem(
      state,
      action: PayloadAction<{
        maintainableItemId: string;
        customerId: string;
      }>,
    ) {
      return {
        ...state,
        customerHierarchiesLoading: true,
      };
    },
    reparentSpace(
      state,
      action: PayloadAction<{
        spaceId: string;
        newParentId: string;
        customerId: string;
      }>,
    ) {
      return {
        ...state,
        customerHierarchiesLoading: true,
      };
    },
    reparentAsset(
      state,
      action: PayloadAction<{
        assetId: string;
        newParentId: string;
        customerId: string;
      }>,
    ) {
      return {
        ...state,
        customerHierarchiesLoading: true,
      };
    },
    reparentSubUnit(
      state,
      action: PayloadAction<{
        subUnitId: string;
        newParentId: string;
        customerId: string;
      }>,
    ) {
      return {
        ...state,
        customerHierarchiesLoading: true,
      };
    },
    reparentMaintainableItem(
      state,
      action: PayloadAction<{
        maintainableItemId: string;
        newParentId: string;
        customerId: string;
      }>,
    ) {
      return {
        ...state,
        customerHierarchiesLoading: true,
      };
    },
    setLoading(state) {
      return {
        ...state,
        hierarchyLoading: true,
      };
    },
    setAssetSearchFilter(state, action: PayloadAction<string>) {
      return {
        ...state,
        assetFilterSearch: action.payload,
      };
    },
    setAssetStatusFilter(state, action: PayloadAction<AssetStatusFilter>) {
      const selectedFilter = action.payload;
      if (selectedFilter === 'all') {
        state.assetFilterStatus = ['all'];
      } else if (state.assetFilterStatus.includes(selectedFilter)) {
        state.assetFilterStatus = state.assetFilterStatus.filter(
          filter => filter !== selectedFilter,
        );
      } else {
        state.assetFilterStatus = [
          ...state.assetFilterStatus.filter(filter => filter !== 'all'),
          selectedFilter,
        ];
      }
      if (state.assetFilterStatus.length === 0) {
        state.assetFilterStatus = initialState.assetFilterStatus;
      }
    },
    setAssetIDsFilter(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        assetFilterIds: action.payload,
      };
    },
    setAssetSortField(state, action: PayloadAction<AssetSortField>) {
      let sortDirection =
        state.assetSortField === action.payload
          ? state.assetSortDirection * -1
          : -1;
      return {
        ...state,
        assetSortField: action.payload,
        assetSortDirection: sortDirection as AssetSortDirection,
      };
    },
    resetAssetTable(state, action: Action) {
      return {
        ...state,
        assetFilterSearch: initialState.assetFilterSearch,
        assetFilterStatus: initialState.assetFilterStatus,
        assetFilterIds: initialState.assetFilterIds,
        assetSortField: initialState.assetSortField,
        assetSortDirection: initialState.assetSortDirection,
      };
    },
    setActiveTreeNodeId(state, action: PayloadAction<string>) {
      return {
        ...state,
        activeTreeNodeId: action.payload,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = assetsNavigatorSlice;
