import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { DropZone, SortableTable } from '@eriksdigital/atomic-ui/components';

import t, { translationString } from 'locales/translation';
import { EntityType, ResourceType, Resource, BgColor } from 'types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { buildAdminCustomerManageRoute } from 'utils/route-helpers';
import { Form } from 'app/elements/Fields/withValidation';
import { Input, Label } from 'app/elements/Fields';
import {
  ButtonOutline,
  ButtonPrimary,
  ButtonSecondary,
} from 'app/elements/Buttons';
import { Div } from 'app/components/Div';
import { H1, H3, Text } from 'app/components/Typography';
import { Modal } from 'app/components/Modal';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useCustomerLocation } from 'app/containers/CustomerLocation';
import { getResourcesForEntity } from 'services/fileManagementServices';
import { reducer, sliceKey, actions } from './slice';
import { selectCustomerEditor } from './selectors';
import { customerEditorSaga } from './saga';

interface Props {}

export function CustomerEditor(props: Props) {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: customerEditorSaga });
  useCustomerLocation({ hasLocationSelector: false });

  const dispatch = useDispatch();
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();

  const {
    customer,
    customerLoading,
    isProcessing,
    customerDeleted,
  } = useSelector(selectCustomerEditor);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [stagingFile, setStagingFile] = useState<File>();
  const [logoLoading, setLogoLoading] = useState(false);
  const [logoPreview, setLogoPreview] = useState<
    Pick<Resource, 'url' | 'description'>
  >();

  const redirectToEditCustomer = useCallback(
    (customerId: string) =>
      history.push(buildAdminCustomerManageRoute(undefined, customerId)),
    [history],
  );

  const loadCustomerLogo = useCallback(async () => {
    if (!customerId) return;
    try {
      setLogoLoading(true);
      const { data: [logo] = [] } = await getResourcesForEntity(customerId, {
        entityType: EntityType.CUSTOMER,
        resourceTypes: [ResourceType.LOGO],
      });
      setLogoPreview(logo);
    } catch (error) {
      console.error(error);
    } finally {
      setLogoLoading(false);
    }
  }, [customerId]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const customerData = { name, customerId: id };
    if (customerId) {
      dispatch(
        actions.updateCustomer({
          id: customerId,
          updateObject: customerData,
          logo: stagingFile,
        }),
      );
    } else {
      dispatch(
        actions.createCustomer({
          customer: customerData,
          logo: stagingFile,
          redirectToEditCustomer,
        }),
      );
    }
  };

  useEffect(() => {
    if (customer) {
      setName(customer.name);
      setId(customer.customerId);
    } else {
      setName('');
      setId('');
    }
  }, [customer]);

  useEffect(() => {
    dispatch(
      customerId ? actions.getCustomer(customerId) : actions.clearCustomer(),
    );
  }, [customerId, dispatch]);

  useEffect(() => {
    if (stagingFile) {
      const reader = new FileReader();
      reader.onload = e =>
        e?.target?.result &&
        setLogoPreview({
          url: e.target.result.toString(),
          description: stagingFile.name,
        });
      reader.readAsDataURL(stagingFile);
    } else {
      setLogoPreview(undefined);
    }
  }, [stagingFile]);

  useEffect(() => {
    setStagingFile(undefined);
    setLogoPreview(undefined);
    if (customer) loadCustomerLogo();
  }, [customer, loadCustomerLogo]);

  // component unmounts
  useEffect(() => {
    return () => {
      dispatch(actions.componentWillUnmount());
    };
  }, [dispatch]);

  if (customerDeleted) return null;

  return (
    <>
      <Helmet>
        <title>CustomerEditor</title>
        <meta name="description" content="Description of CustomerEditor" />
      </Helmet>

      <Div mb="24px">
        <H1>
          {t(customerId ? 'Customer.EditCustomer' : 'Customer.NewCustomer')}
        </H1>
      </Div>

      {customerLoading ? (
        <LoadingIndicator />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Div padding="24px" mb="24px" bg={BgColor.white}>
            <H3>{t('CustomerDetails')}</H3>

            <Div display="flex" mt="16px">
              <InputField
                label="CustomerName"
                value={name}
                onChange={setName}
                required
              />
              <InputField
                label="CustomerId"
                value={id}
                onChange={setId}
                required
              />
            </Div>

            <Div mt="16px">
              <Label>{t(`CustomerLogo`)}</Label>
              {logoLoading ? (
                <LoadingIndicator />
              ) : (
                <DropZone
                  id="customer_logo_dropzone"
                  onChange={fileList => setStagingFile(fileList[0])}
                  uploadInstructionText={t('Drag&Drop')}
                  linkText={t('ClickToUpload')}
                  accept="image/*"
                >
                  {logoPreview && (
                    <SortableTable
                      header={[
                        { header: 'Preview' },
                        { header: t('Attachments.StagingTable.Name') },
                      ]}
                      data={[
                        [
                          <img
                            alt="customer_logo"
                            height={40}
                            src={logoPreview.url}
                          />,
                          <Text>
                            {stagingFile?.name || logoPreview.description}
                          </Text>,
                        ],
                      ]}
                    />
                  )}
                </DropZone>
              )}
            </Div>
          </Div>

          <ActionButtons
            disabled={isProcessing || !name || !id}
            isProcessing={isProcessing}
            onReset={() => history.goBack()}
          />
        </Form>
      )}

      {customerId && (
        <DeleteSection onDelete={() => setDeleteModalOpen(true)} />
      )}

      {deleteModalOpen && (
        <DeleteModal
          onCancel={() => setDeleteModalOpen(false)}
          onDelete={() => {
            dispatch(actions.deleteCustomer(customerId));
            setDeleteModalOpen(false);
          }}
        />
      )}
    </>
  );
}

const InputField = ({ label, value, required, onChange }) => (
  <Div width="50%" mr="24px">
    <Label required={required}>{t(label)}</Label>
    <Input
      type="text"
      name={label}
      value={value}
      required={required}
      onChange={e => onChange(e.target.value)}
    />
  </Div>
);

const ActionButtons = ({ isProcessing, onReset, disabled }) => (
  <Div display="flex" align="center" justifyContent="flex-end" gap="24px">
    <ButtonSecondary type="reset" onClick={onReset}>
      {t('Cancel')}
    </ButtonSecondary>
    <ButtonPrimary type="submit" disabled={disabled}>
      {isProcessing
        ? `${translationString('Processing')}...`
        : translationString('Save')}
    </ButtonPrimary>
  </Div>
);

const DeleteSection = ({ onDelete }) => (
  <Div mt="48px" padding="24px" bg={BgColor.white}>
    <H3>{t('DeleteCustomer')}</H3>
    <Text fontSize="ft14">{t('DeleteCustomerDialog')}</Text>
    <Div mt="24px">
      <ButtonOutline onClick={onDelete}>{t('Delete')}</ButtonOutline>
    </Div>
  </Div>
);

const DeleteModal = ({ onCancel, onDelete }) => (
  <Modal onClose={onCancel} withOutsideClose>
    <H3>{t('Customer.DeleteCustomerHeading')}</H3>
    <Text fontSize="ft14">{t('Customer.DeleteCustomerConfirm')}</Text>
    <Div display="flex" align="center" justifyContent="flex-end" gap="24px">
      <ButtonSecondary type="reset" onClick={onCancel}>
        {t('Cancel')}
      </ButtonSecondary>
      <ButtonPrimary id="delete_button" type="submit" onClick={onDelete}>
        {t('Delete')}
      </ButtonPrimary>
    </Div>
  </Modal>
);
