import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { translationString } from 'locales/translation';
import {
  Customer,
  CreateResourceFileObject,
  EntityType,
  ResourceType,
} from 'types';
import apiCall from 'services/utils/apiCall';
import { objectToFormData } from 'utils/form-data';
import {
  getCustomer,
  postCustomer,
  putCustomer,
  deleteCustomer as deleteCustomerAPI,
} from 'services/customersService';
import { postResource as postResourceAPI } from 'services/fileManagementServices';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import { actions } from './slice';

export function* getCustomers(action: PayloadAction<string>) {
  try {
    const { data } = yield apiCall(getCustomer, action.payload);

    yield put(actions.getCustomerSuccess(data));
  } catch (error) {
    yield put(
      announcementActions.addError(
        translationString('ErrorMessage.FetchEntity.Customers'),
      ),
    );
  }
}

export function* createCustomer(
  action: PayloadAction<{
    customer: Customer;
    logo: File;
    redirectToEditCustomer: (customerId: string) => void;
  }>,
) {
  try {
    const { customer, logo, redirectToEditCustomer } = action.payload;
    const { name, customerId } = customer;
    const { data } = yield apiCall(postCustomer, customer);

    if (logo) {
      const logoData: FormData = objectToFormData<CreateResourceFileObject>({
        customerId: data.id,
        description: logo.name,
        entityType: EntityType.CUSTOMER,
        file: logo,
        resourceType: ResourceType.LOGO,
      });
      yield apiCall(postResourceAPI, logoData, data.id);
    }

    yield put(
      actions.getCustomerSuccess({
        name,
        customerId,
      }),
    );
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.SavedCustomer'),
      ),
    );

    redirectToEditCustomer(data.id);
  } catch (error) {
    yield put(
      announcementActions.addError(
        translationString('ErrorMessage.CustomerAddError'),
      ),
    );
  }
}

export function* updateCustomer(
  action: PayloadAction<{
    id: string;
    updateObject: Customer;
    logo?: File;
  }>,
) {
  try {
    const { id, updateObject, logo } = action.payload;
    const { data } = yield apiCall(putCustomer, id, updateObject);

    if (logo && data.id) {
      const logoData: FormData = objectToFormData<CreateResourceFileObject>({
        customerId: data.id,
        description: logo.name,
        entityType: EntityType.CUSTOMER,
        file: logo,
        resourceType: ResourceType.LOGO,
      });
      yield apiCall(postResourceAPI, logoData, data.id);
    }

    yield put(actions.getCustomerSuccess(data));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.SavedCustomer'),
      ),
    );
  } catch (error) {
    yield put(
      announcementActions.addError(
        translationString('ErrorMessage.CustomerUpdateError'),
      ),
    );
  }
}

export function* deleteCustomer(action: PayloadAction<string>) {
  try {
    yield apiCall(deleteCustomerAPI, action.payload);

    // TODO: refresh list of customer
    yield put(actions.deleteCustomerSucces());
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.DeletedCustomer'),
      ),
    );
  } catch (error) {
    yield put(
      announcementActions.addError(
        translationString('ErrorMessage.CustomerDeleteError'),
      ),
    );
  }
}

export function* customerEditorSaga() {
  yield takeLatest(actions.getCustomer.type, getCustomers);
  yield takeLatest(actions.createCustomer.type, createCustomer);
  yield takeLatest(actions.updateCustomer.type, updateCustomer);
  yield takeLatest(actions.deleteCustomer.type, deleteCustomer);
}
