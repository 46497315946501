import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  UploadTemplatesPayload,
  EquipmentFilters,
} from './types';
import {
  EntityType,
  HierarchyNode,
  MaintainableItem,
  MaintainableItemMetadata,
  FieldType,
} from 'types';
import { defaultPageSize } from 'app/components/Pagination/usePaginationRedux';
import { DataTableSort } from 'app/components/DataTable/types';

// The initial state of the Equipments container
export const initialState: ContainerState = {
  locationId: undefined,
  miTypeId: undefined,
  metadata: {
    data: undefined,
    loading: false,
    error: undefined,
  },
  equipmentTypes: {
    data: undefined,
    loading: false,
    error: undefined,
  },
  equipments: {
    data: undefined,
    allMap: {},
    pageInfo: {
      pageSize: defaultPageSize,
      page: 1,
      pageCount: undefined,
      totalRecords: undefined,
    },
    sort: undefined,
    searchText: undefined,
    filters: {},
    loading: false,
    error: undefined,
  },
  hierarchyTree: undefined,
};

const equipmentsSlice = createSlice({
  name: 'equipments',
  initialState,
  reducers: {
    getMetadata(state) {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: true,
          error: initialState.metadata.error,
        },
      };
    },
    getMetadataSuccess(state, action: PayloadAction<MaintainableItemMetadata>) {
      return {
        ...state,
        metadata: {
          data: action.payload,
          loading: initialState.metadata.loading,
          error: initialState.metadata.error,
        },
      };
    },
    getMetadataSkipped(state) {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: initialState.metadata.loading,
          error: initialState.metadata.error,
        },
      };
    },
    getMetadataError(state, action: PayloadAction<string>) {
      return {
        ...state,
        metadata: {
          data: initialState.metadata.data,
          loading: initialState.metadata.loading,
          error: action.payload,
        },
      };
    },

    getEquipmentTypes(state, action: PayloadAction<string>) {
      return {
        ...state,
        equipmentTypes: {
          data: initialState.equipmentTypes.data,
          loading: true,
          error: initialState.equipmentTypes.error,
        },
      };
    },
    getEquipmentTypesSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        equipmentTypes: {
          data: action.payload,
          loading: initialState.equipmentTypes.loading,
          error: initialState.equipmentTypes.error,
        },
      };
    },
    getEquipmentTypesError(state, action: PayloadAction<string>) {
      return {
        ...state,
        equipmentTypes: {
          data: initialState.equipmentTypes.data,
          loading: initialState.equipmentTypes.loading,
          error: action.payload,
        },
      };
    },

    getEquipments(
      state,
      action: PayloadAction<{
        locationId: string;
        miTypeId: string;
      }>,
    ) {
      return {
        ...state,
        locationId: action.payload.locationId,
        miTypeId: action.payload.miTypeId,
        equipments: {
          ...initialState.equipments,
          allMap: state.equipments.allMap,
          loading: true,
        },
      };
    },

    getEquipmentsSuccess(
      state,
      action: PayloadAction<{
        data: MaintainableItem[];
        pageCount: number;
        totalRecords: number;
      }>,
    ) {
      return {
        ...state,
        equipments: {
          ...state.equipments,
          data: action.payload.data,
          allMap: action.payload.data.reduce(
            (acc, item) => ({ ...acc, [item.id]: { ...item } }),
            state.equipments.allMap,
          ),
          pageInfo: {
            ...state.equipments.pageInfo,
            pageCount: action.payload.pageCount,
            totalRecords: action.payload.totalRecords,
          },
          loading: initialState.equipments.loading,
          error: initialState.equipments.error,
        },
      };
    },

    getEquipmentsError(state, action: PayloadAction<string>) {
      return {
        ...state,
        equipments: {
          ...initialState.equipments,
          allMap: state.equipments.allMap,
          error: action.payload,
        },
      };
    },

    setPageInfo(
      state,
      action: PayloadAction<{
        page: number;
        pageSize: number;
      }>,
    ) {
      return {
        ...state,
        equipments: {
          ...state.equipments,
          pageInfo: {
            ...state.equipments.pageInfo,
            page: action.payload.page,
            pageSize: action.payload.pageSize,
          },
          loading: true,
        },
      };
    },

    setSort(state, action: PayloadAction<DataTableSort>) {
      return {
        ...state,
        equipments: {
          ...state.equipments,
          sort: action.payload,
          loading: true,
        },
      };
    },

    setSearch(state, action: PayloadAction<string>) {
      return {
        ...state,
        equipments: {
          ...state.equipments,
          searchText: action.payload,
          pageInfo: {
            ...state.equipments.pageInfo,
            page: 1,
          },
          loading: true,
        },
      };
    },

    setFilters(
      state,
      action: PayloadAction<{
        id: string;
        type: FieldType;
        values: string[];
      }>,
    ) {
      return {
        ...state,
        equipments: {
          ...state.equipments,
          filters: {
            ...state.equipments.filters,
            [action.payload.id]: {
              type: action.payload.type,
              values: action.payload.values,
            },
          },
          pageInfo: {
            ...state.equipments.pageInfo,
            page: 1,
          },
          loading: true,
        },
      };
    },

    setUrlFilters(
      state,
      action: PayloadAction<{
        locationId: string;
        miTypeId: string;
        filters: EquipmentFilters;
      }>,
    ) {
      return {
        ...state,
        locationId: action.payload.locationId,
        miTypeId: action.payload.miTypeId,
        equipments: {
          ...state.equipments,
          filters: action.payload.filters,
          loading: true,
        },
      };
    },

    refetchEquipments(state) {
      return {
        ...state,
        equipments: {
          ...state.equipments,
          pageInfo: {
            ...initialState.equipments.pageInfo,
          },
          loading: true,
        },
      };
    },

    uploadTemplates(state, action: PayloadAction<UploadTemplatesPayload>) {
      return {
        ...state,
      };
    },

    getHierarchyTree(
      state,
      action: PayloadAction<{
        customerId: string;
        lowestItemType?: EntityType;
      }>,
    ) {
      return {
        ...state,
        hierarchyTree: undefined,
      };
    },
    getHierarchyTreeSuccess(state, action: PayloadAction<HierarchyNode[]>) {
      return {
        ...state,
        hierarchyTree: action.payload,
      };
    },
    getHierarchyTreeFail(state, action: PayloadAction<string>) {
      return {
        ...state,
        hierarchyTree: undefined,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = equipmentsSlice;
