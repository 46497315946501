import styled from 'styled-components/macro';
import { mediaQuery } from 'utils/mediaQuery';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.default.gray1};
  padding: ${({ theme }) => theme.spacing.sp16};

  ${mediaQuery.phoneLarge} {
    padding: ${({ theme }) => theme.spacing.sp24};
  }
`;
