import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { translationString } from 'locales/translation';
import { MaintainableItemMetadataType, Translated } from 'types';
import { reducer, sliceKey } from '../slice';
import { equipmentsSaga } from '../saga';
import { selectAllEquipmentTypes } from '../selectors';

function useEquipmentTypesAll() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentsSaga });

  const allEquipmentTypesRaw = useSelector(selectAllEquipmentTypes);

  const allEquipmentTypes = useMemo(() => {
    const translated = allEquipmentTypesRaw?.map(item => ({
      ...item,
      translation: translationString(
        `EquipmentTypes.${item.maintainableItemType}`,
      ),
    })) as Translated<MaintainableItemMetadataType>[];

    return translated ? sortBy(translated, 'translation') : [];
  }, [allEquipmentTypesRaw]);

  const allEquipmentTypesMap = useMemo(() => {
    const map = new Map<string, Translated<MaintainableItemMetadataType>>();

    allEquipmentTypes.forEach(item => {
      map.set(item.maintainableItemTypeId, item);
    });

    return map;
  }, [allEquipmentTypes]);

  return { allEquipmentTypes, allEquipmentTypesMap };
}

export default useEquipmentTypesAll;
