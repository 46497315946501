import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { translationString } from 'locales/translation';
import {
  ButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
} from 'app/elements/Buttons';
import { Text } from 'app/components/Typography';
import { Form } from 'app/elements/Fields/withValidation';
import { TextColor } from 'types';
import { mediaQuery } from 'utils/mediaQuery';

type Props = React.FormHTMLAttributes<HTMLFormElement> & {
  intro?: ReactNode;
  confirm?: ReactNode;
  isConfirmDisabled?: boolean;
  onCancel?: () => void;
};

function SideForm({
  intro,
  confirm = translationString('Confirm'),
  isConfirmDisabled = false,
  onCancel,
  onSubmit,
  children,
  ...rest
}: Props) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit?.(e);
  };

  return (
    <FormStyled onSubmit={handleSubmit} {...rest}>
      {intro && (
        <Text color={TextColor.secondaryNew} fontSize="ft14">
          {intro}
        </Text>
      )}
      {children}
      <ButtonGroup>
        {onCancel && (
          <ButtonSecondary type="button" onClick={onCancel}>
            {translationString('Cancel')}
          </ButtonSecondary>
        )}
        <ButtonPrimary type="submit" disabled={isConfirmDisabled}>
          {confirm}
        </ButtonPrimary>
      </ButtonGroup>
    </FormStyled>
  );
}

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;

  ${mediaQuery.phoneLarge} {
    width: 460px;
  }
`;

export default SideForm;
