/**
 *
 * Asynchronously loads the component for SpaceDashboard
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SpaceDashboard = lazyLoad(
  () => import('./index'),
  module => module.SpaceDashboard,
);
