import React from 'react';
import { PageContent } from 'app/elements/Page';
import { H1 } from 'app/components/Typography';

export function Sandbox() {
  return (
    <PageContent>
      <H1>Sandbox</H1>
    </PageContent>
  );
}
