import { PayloadAction } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { EquipmentAttachmentsState, UploadResourcePayload } from './types';
import {
  Resource,
  ResourceType,
  UpdateResourceObject,
  ProcessStatus,
} from 'types';

export const initialState: EquipmentAttachmentsState = {
  data: undefined,
  loading: false,
  error: undefined,
  isUploading: false,
  uploadingStatus: undefined,
  resourceTypes: [],
};

const equipmentAttachmentsSlice = createSlice({
  name: 'equipmentAttachments',
  initialState,
  reducers: {
    getDocumentsAndCertificates(state) {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    },
    getDocumentsAndCertificatesSuccess(
      state,
      action: PayloadAction<Resource[]>,
    ) {
      return {
        ...state,
        data: action.payload,
        error: undefined,
        loading: false,
      };
    },
    getDocumentsAndCertificatesError(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    editDocumentsAndCertificates(
      state,
      action: PayloadAction<{
        entityId: string;
        resourceId: string;
        updateObject: UpdateResourceObject;
      }>,
    ) {
      return {
        ...state,
        loading: true,
        error: '',
      };
    },
    editDocumentsAndCertificatesFailure(
      state,
      action: PayloadAction<{
        entityId: string;
        resourceId: string;
        updateObject: UpdateResourceObject;
        error: string;
      }>,
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    },
    deleteDocumentsAndCertificates(
      state,
      action: PayloadAction<{
        entityId: string;
        resourceId: string;
      }>,
    ) {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    },
    deleteDocumentsAndCertificatesFailure(
      state,
      action: PayloadAction<{ entityId: string; error: string }>,
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    },
    setIsUploading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isUploading: action.payload,
        uploadingStatus: initialState.uploadingStatus,
      };
    },
    uploadAttachments(state, action: PayloadAction<UploadResourcePayload>) {
      return {
        ...state,
        uploadingStatus: 'pending',
      };
    },
    setUploadStatus(state, action: PayloadAction<ProcessStatus>) {
      return {
        ...state,
        uploadingStatus: action.payload,
      };
    },
    getResourceTypes(state) {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    },
    getResourceTypesSuccess(state, action: PayloadAction<ResourceType[]>) {
      return {
        ...state,
        loading: false,
        error: undefined,
        resourceTypes: sortBy(action.payload),
      };
    },
    getResourceTypesFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    getResourceTypesSkipped(state) {
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = equipmentAttachmentsSlice;
