import React, { forwardRef } from 'react';

type Props = React.FormHTMLAttributes<HTMLFormElement>;

export default forwardRef<HTMLFormElement, Props>(function Form(
  { children, ...rest },
  ref,
) {
  const handleInvalid = (e: React.FormEvent) => {
    e.preventDefault();

    if (e.isTrusted) {
      e.currentTarget
        .querySelector<HTMLInputElement>('input:invalid, select:invalid')
        ?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <form
      ref={ref}
      {...rest}
      onInvalid={handleInvalid}
      onKeyDown={handleKeyDown}
    >
      {children}
    </form>
  );
});
