import React from 'react';
import sortBy from 'lodash/sortBy';
import { Icon } from '@eriksdigital/atomic-ui/components';
import {
  CheckCircleIcon,
  RemoveIcon,
} from '@eriksdigital/atomic-ui/components/Icons';

import { translationString } from 'locales/translation';
import { formatDate, convertToIsoDateFormat } from 'locales/date-format-i18n';
import {
  IconColor,
  MaintainableItem,
  Task,
  TaskTypeField,
  TaskTypeMetadata,
  LinkPath,
} from 'types';
import { Link } from 'app/elements/Links';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';
import { DataTableColumn, DataTableRow } from 'app/components/DataTable/types';
import { capitalizeFirstLetter } from 'utils/formatters';
import { buildTaskDetailsRoute } from 'utils/route-helpers';
import { InspectionType, TaskFieldValue } from './types';
import { selectInspectionsHydrated } from './selectors';

export const inspectionTypeFieldNames: InspectionType[] = [
  'visualInspection',
  'hydrostaticTest',
  'electricalResistanceTest',
];

const inspectionsTableColumnTitles: Array<string> = [
  'taskType',
  'description',
  'taskFieldValues',
  'createdDate',
  'result',
  'assignee',
];

export const inspectionsTableColumns = (): Array<DataTableColumn> =>
  inspectionsTableColumnTitles.map((columnData: string, index: number) => {
    return {
      order: index,
      visible: true,
      type: 'string',
      id: columnData,
      label: translationString(`Inspections.${columnData}`),
      sortable: false,
    };
  });

type InspectionHydrated = ReturnType<typeof selectInspectionsHydrated>[number];

export const mapInspectionsToDataTableRow = (
  inspection: InspectionHydrated,
  customerId: string,
  locationId: string,
  pathname: string,
): DataTableRow => ({
  id: inspection.id,
  values: inspectionTableAttributes(
    inspection,
    customerId,
    locationId,
    pathname,
  ),
});

export const inspectionTableAttributes = (
  inspection: InspectionHydrated,
  customerId: string,
  locationId: string,
  pathname: string,
) => {
  const isPass = inspection.result === 'pass';
  const linkPath: LinkPath = {
    pathname: buildTaskDetailsRoute(customerId, locationId, inspection.id),
    state: {
      prevPath: pathname,
      prevName: inspection.maintainableItemName,
    },
  };

  return [
    {
      id: 'taskType',
      value: (
        <Link to={linkPath}>
          {getInspectionTypesLabel(inspection.taskTypes as InspectionType[])}
        </Link>
      ),
    },
    {
      id: 'description',
      value: inspection.comment,
    },
    {
      id: 'taskFieldValues',
      value: inspection.notes,
    },
    {
      id: 'createdDate',
      value: inspection.inspectionDate && formatDate(inspection.inspectionDate),
    },
    {
      id: 'result',
      value: (
        <Div display="flex" gap="8px">
          <Icon
            as={isPass ? CheckCircleIcon : RemoveIcon}
            size="sz16"
            color={isPass ? IconColor.green : IconColor.red}
          />
          <Text>{capitalizeFirstLetter(inspection.result)}</Text>
        </Div>
      ),
    },
    {
      id: 'assignee',
      value: inspection.inspectorName,
    },
  ];
};

export function getInspectionTypes(equipments: MaintainableItem[]) {
  const inspectionTypes = {
    visualInspection: (equipment: MaintainableItem) =>
      equipment.attributes?.some(
        attr => attr.id === 'visualInspection' && attr.value === 'YES',
      ),
    hydrostaticTest: (equipment: MaintainableItem) =>
      equipment.attributes?.some(
        attr => attr.id === 'hydrostaticTest' && attr.value === 'YES',
      ),
    electricalResistanceTest: (equipment: MaintainableItem) =>
      equipment.attributes?.some(
        attr => attr.id === 'electricalResistanceTest' && attr.value === 'YES',
      ),
  };

  return Object.keys(inspectionTypes).filter(inspectionType =>
    equipments.every(equipment => inspectionTypes[inspectionType](equipment)),
  ) as InspectionType[];
}

export function getInspectionTaskTypeId(data?: TaskTypeMetadata[]) {
  return data?.find(({ mainType }) => mainType === 'INSPECTION')?.id || '';
}

export function getSortedInspectionFields(metadata: TaskTypeMetadata[]) {
  const inspection = metadata?.find(
    ({ mainType }) => mainType === 'INSPECTION',
  );
  return inspection ? sortBy(inspection.taskTypeFields, 'sequence') : [];
}

export function separateInspectionFields(
  fields: TaskTypeField[],
  equipments: MaintainableItem[],
) {
  const initialValue = {
    inspectionTypeFields: new Array<TaskTypeField>(),
    equipmentFields: new Array<TaskTypeField>(),
    commonFields: new Array<TaskTypeField>(),
  };

  if (!fields.length || !equipments.length) {
    return initialValue;
  }

  const attributeIds = Array.from(
    new Set(
      equipments.flatMap(({ attributes }) => attributes?.map(({ id }) => id)),
    ),
  );

  return fields.reduce((acc, field) => {
    if (inspectionTypeFieldNames.includes(field.fieldName as InspectionType)) {
      return {
        ...acc,
        inspectionTypeFields: [...acc.inspectionTypeFields, field],
      };
    } else if (attributeIds.includes(field.fieldName)) {
      return {
        ...acc,
        equipmentFields: [...acc.equipmentFields, field],
      };
    } else {
      return {
        ...acc,
        commonFields: [...acc.commonFields, field],
      };
    }
  }, initialValue);
}

export function findFieldByName(fields: TaskTypeField[], fieldName: string) {
  return fields.find(field => field.fieldName === fieldName);
}

export function findEnumByKey(field: TaskTypeField, key: string) {
  return field?.taskTypeFieldEnums.find(item => item.key === key);
}

export function findEnumById(field: TaskTypeField, id: string) {
  return field?.taskTypeFieldEnums.find(item => item.id === id);
}

export function getFieldValueByFieldName(
  taskFieldValues: TaskFieldValue[],
  fieldName: string,
) {
  return taskFieldValues.find(fieldValue => fieldValue.fieldName === fieldName)
    ?.value;
}

export function getFieldValueEnumKeyByFieldName(
  fields: TaskTypeField[],
  taskFieldValues: TaskFieldValue[],
  fieldName: string,
) {
  const field = findFieldByName(fields, fieldName);
  const fieldValue = getFieldValueByFieldName(taskFieldValues, fieldName);

  return field && fieldValue ? findEnumById(field, fieldValue)?.key : undefined;
}

export function getFieldValueFromEquipment(
  { taskTypeFieldId, fieldName, field }: TaskFieldValue,
  equipment: MaintainableItem,
): TaskFieldValue {
  const attr = equipment.attributes?.find(attr => attr.id === fieldName);

  if (field?.fieldType === 'enum') {
    const enumItem = attr ? findEnumByKey(field, attr?.value) : null;

    return { fieldName, taskTypeFieldId, value: enumItem?.id };
  }

  return { fieldName, taskTypeFieldId, value: attr?.value };
}

export function mapTaskFieldValues(
  taskFieldValues: TaskFieldValue[],
  equipment: MaintainableItem,
): TaskFieldValue[] {
  return taskFieldValues.map(field => {
    const { fieldName, taskTypeFieldId, value, fromEquipment } = field;

    if (!fromEquipment) {
      return { fieldName, taskTypeFieldId, value };
    }

    return getFieldValueFromEquipment(field, equipment);
  });
}

export function getInspectionResult(
  fields: TaskTypeField[],
  formData: FormData,
) {
  const field = findFieldByName(fields, 'result');
  const value = formData.get('result')?.toString();
  const enumValue = field?.taskTypeFieldEnums.find(({ id }) => id === value);

  return enumValue?.name || '';
}

export function buildTaskFieldValues(
  commonFields: TaskTypeField[],
  inspectionTypeFields: TaskTypeField[],
  equipmentFields: TaskTypeField[],
  selectedInspectionTypes: InspectionType[],
  formData: FormData,
) {
  const taskFieldValues = new Array<TaskFieldValue>();

  commonFields.forEach(({ id: taskTypeFieldId, fieldName, fieldType }) => {
    let value = formData.get(fieldName)?.toString();

    if (value) {
      if (fieldType === 'date') {
        value = convertToIsoDateFormat(value);
      }

      taskFieldValues.push({ fieldName, taskTypeFieldId, value });
    }
  });

  inspectionTypeFields.forEach(field => {
    const { id: taskTypeFieldId, fieldName } = field;
    const inspectionType = fieldName as InspectionType;
    const enumKey = selectedInspectionTypes.includes(inspectionType)
      ? 'yes'
      : 'no';
    const enumItem = findEnumByKey(field, enumKey);
    const value = enumItem?.id;

    taskFieldValues.push({ taskTypeFieldId, value, fieldName });

    enumItem?.conditionalTaskTypeFieldList.forEach(conditionalFieldId => {
      const equipmentField = equipmentFields.find(
        item => item.id === conditionalFieldId,
      );
      if (equipmentField) {
        taskFieldValues.push({
          taskTypeFieldId: equipmentField.id,
          fieldName: equipmentField.fieldName,
          fromEquipment: true,
          field: equipmentField,
        });
      }
    });
  });

  equipmentFields.forEach(field => {
    const { id: taskTypeFieldId, fieldName } = field;

    taskFieldValues.push({
      taskTypeFieldId,
      fieldName,
      fromEquipment: true,
      field,
    });
  });

  return taskFieldValues;
}

export function getInspectionTypesLabel(inspectionTypes: InspectionType[]) {
  return inspectionTypes
    .map(fieldName => translationString(`Inspections.Type.${fieldName}`))
    .join(', ');
}

export function hydrateInspections(
  inspections: Task[],
  fields: TaskTypeField[],
) {
  return inspections?.map(inspection => {
    const { taskFieldValues, id } = inspection;
    const taskTypes = taskFieldValues
      .filter(fieldValue => {
        const isInspectionType = inspectionTypeFieldNames.includes(
          fieldValue.fieldName as InspectionType,
        );
        const enumKey = getFieldValueEnumKeyByFieldName(
          fields,
          taskFieldValues,
          fieldValue.fieldName,
        );

        return isInspectionType && enumKey === 'yes';
      })
      .map(fieldValue => fieldValue.fieldName);

    const comment = getFieldValueByFieldName(taskFieldValues, 'comment');
    const notes = getFieldValueByFieldName(taskFieldValues, 'notes');
    const inspectorName = getFieldValueByFieldName(
      taskFieldValues,
      'inspectorName',
    );
    const inspectionDate = getFieldValueByFieldName(
      taskFieldValues,
      'inspectionDate',
    );
    const result = getFieldValueEnumKeyByFieldName(
      fields,
      taskFieldValues,
      'result',
    );

    return {
      id,
      taskTypes,
      comment,
      notes,
      inspectionDate,
      result,
      inspectorName,
      maintainableItemName: inspection.maintainableItemName,
    };
  });
}
