import React from 'react';
import { Link } from 'react-router-dom';
import { SettingsIcon } from '@eriksdigital/atomic-ui/components/Icons';
import { H1, H2 } from '../../../components/Typography';
import MenuItem from './MenuItem';
import t, { translationString } from 'locales/translation';
import { useSelector } from 'react-redux';
import {
  selectSelectedCustomer,
  selectSelectedLocation,
} from 'app/containers/CustomerLocation/selectors';
import RouteHelpers from 'utils/route-helpers';
import { useAuth } from 'app/providers/AuthProvider';
import { CAM, TAM, FAM } from 'app/providers/AuthProvider/roles';

function MenuSettings() {
  const customerId = useSelector(selectSelectedCustomer);
  const locationId = useSelector(selectSelectedLocation);
  const { userHasRole } = useAuth();

  if (!customerId || !userHasRole([TAM, FAM, CAM])) {
    return null;
  }

  return (
    <MenuItem
      icon={SettingsIcon}
      label={translationString('Settings')}
      subMenu="settings"
    >
      <H1>{t('Settings')}</H1>
      <H2>{t('CustomerSettings')}</H2>
      {userHasRole([TAM, FAM]) && (
        <Link
          to={RouteHelpers.buildAdminCustomerManageRoute(undefined, customerId)}
        >
          {t('MenuSettings.CustomerDetails')}
        </Link>
      )}
      <Link
        to={RouteHelpers.buildAdminCustomerManageRoute('users', customerId)}
      >
        {t('MenuSettings.UserManagement')}
      </Link>
      {userHasRole([TAM, FAM]) && (
        <>
          <Link
            to={RouteHelpers.buildAdminCustomerManageRoute(
              'locations',
              customerId,
            )}
          >
            {t('Resources.Locations')}
          </Link>

          <Link
            to={RouteHelpers.buildAdminCustomerManageRoute(
              'spaces',
              customerId,
            )}
          >
            {t('CustomerHierarchy')}
          </Link>
        </>
      )}

      {userHasRole([TAM, FAM, CAM]) && (
        <>
          <H2>{t('MenuSettings.Other')}</H2>

          <Link to={RouteHelpers.buildTagsRoute(customerId, locationId)}>
            {t('Tags.Title')}
          </Link>

          {userHasRole([TAM, FAM]) && (
            <Link
              to={RouteHelpers.buildAdminCustomerManageRoute(
                'surveys',
                customerId,
              )}
            >
              {t('UploadSurvey')}
            </Link>
          )}

          <Link
            to={RouteHelpers.buildAdminCustomerManageRouteWithLocation(
              'logs',
              customerId,
              locationId,
            )}
          >
            {t('MenuSettings.UploadLogs')}
          </Link>
        </>
      )}
    </MenuItem>
  );
}

export default MenuSettings;
