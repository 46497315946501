/**
 *
 * AdminPage
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, Redirect } from 'react-router-dom';

import { PageContent } from 'app/elements/Page';
import { CustomerEditor } from 'app/containers/CustomerEditor';
import { CustomerManagement } from 'app/containers/CustomerManagement/Loadable';
import { UserSettings } from 'app/containers/UserSettings/Loadable';
import { ADMIN_BASE_ROUTES } from './constants';

interface Props {}

export function AdminPage(props: Props) {
  return (
    <>
      <Helmet>
        <title>AdminPage</title>
        <meta name="description" content="Description of AdminPage" />
      </Helmet>
      <PageContent>
        <Switch>
          <Route exact path={ADMIN_BASE_ROUTES.CUSTOMER}>
            <CustomerEditor />
          </Route>
          <Route path={ADMIN_BASE_ROUTES.CUSTOMER}>
            <CustomerManagement />
          </Route>
          <Route path={ADMIN_BASE_ROUTES.SETTINGS}>
            <UserSettings />
          </Route>
          <Route>
            <Redirect to={ADMIN_BASE_ROUTES.SETTINGS} />
          </Route>
        </Switch>
      </PageContent>
    </>
  );
}
