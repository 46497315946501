export function objectToFormData<T>(input: T): FormData {
  const formData = new FormData();
  Object.keys(input).forEach(key => {
    formData.append(key, input[key]);
  });
  return formData;
}

export function formDataToObject<T>(formData: FormData, withEmpty = true): T {
  const object: any = {};
  formData.forEach((value, key) => {
    if (withEmpty || value) {
      object[key] = value;
    }
  });
  return object as T;
}
