import { useCallback, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { translationString } from 'locales/translation';
import { EquipmentType, Translated } from 'types';
import { reducer, sliceKey, actions } from '../slice';
import { equipmentsSaga } from '../saga';
import {
  selectEquipmentTypes,
  selectIsLoadingEquipmentTypes,
} from '../selectors';

type Props = {
  locationId?: string;
};

function useEquipmentTypes({ locationId }: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentsSaga });

  const dispatch = useDispatch();

  const equipmentTypesRaw = useSelector(selectEquipmentTypes);
  const isLoadingEquipmentTypes = useSelector(selectIsLoadingEquipmentTypes);

  const equipmentTypes = useMemo<Translated<EquipmentType>[]>(() => {
    const translated = equipmentTypesRaw?.map(item => ({
      ...item,
      translation: translationString(`EquipmentTypes.${item.equipmentSubType}`),
    }));

    return translated ? sortBy(translated, 'translation') : [];
  }, [equipmentTypesRaw]);

  const fetchEquipmentTypes = useCallback(() => {
    if (locationId) {
      dispatch(actions.getEquipmentTypes(locationId));
    }
  }, [dispatch, locationId]);

  return {
    equipmentTypes,
    isLoadingEquipmentTypes,
    fetchEquipmentTypes,
  };
}

export default useEquipmentTypes;
