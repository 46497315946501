import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components/macro';

export const Notification = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_418_13924)">
        <path
          d="M17.9419 12.0581L16.25 10.3662V8.125C16.248 6.57622 15.6718 5.08319 14.6328 3.93462C13.5938 2.78605 12.1658 2.06352 10.625 1.90675V0.625H9.375V1.90675C7.83417 2.06352 6.4062 2.78605 5.36721 3.93462C4.32822 5.08319 3.75201 6.57622 3.75 8.125V10.3662L2.05806 12.0581C1.94087 12.1753 1.87502 12.3343 1.875 12.5V14.375C1.875 14.5408 1.94085 14.6997 2.05806 14.8169C2.17527 14.9342 2.33424 15 2.5 15H6.875V15.4855C6.86145 16.2784 7.14095 17.0483 7.65993 17.6479C8.17891 18.2475 8.90088 18.6344 9.6875 18.7347C10.122 18.7778 10.5606 18.7295 10.9753 18.5929C11.39 18.4563 11.7715 18.2344 12.0953 17.9415C12.419 17.6486 12.6779 17.2912 12.8553 16.8922C13.0326 16.4933 13.1245 16.0616 13.125 15.625V15H17.5C17.6658 15 17.8247 14.9342 17.9419 14.8169C18.0592 14.6997 18.125 14.5408 18.125 14.375V12.5C18.125 12.3343 18.0591 12.1753 17.9419 12.0581ZM11.875 15.625C11.875 16.1223 11.6775 16.5992 11.3258 16.9508C10.9742 17.3025 10.4973 17.5 10 17.5C9.50272 17.5 9.02581 17.3025 8.67417 16.9508C8.32254 16.5992 8.125 16.1223 8.125 15.625V15H11.875V15.625ZM16.875 13.75H3.125V12.7588L4.81687 11.0669C4.93409 10.9497 4.99996 10.7907 5 10.625V8.125C5 6.79892 5.52678 5.52715 6.46447 4.58947C7.40215 3.65178 8.67392 3.125 10 3.125C11.3261 3.125 12.5979 3.65178 13.5355 4.58947C14.4732 5.52715 15 6.79892 15 8.125V10.625C15 10.7907 15.0659 10.9497 15.1831 11.0669L16.875 12.7588V13.75Z"
          fill={theme.colors.text.secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_418_13924">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const StopNotification = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_418_13929)">
        <path
          d="M16.25 10.3662V8.125C16.2494 7.14673 16.0174 6.18246 15.5729 5.311L18.75 2.13381L17.8662 1.25L1.25 17.8662L2.13381 18.75L5.88381 15H6.875V15.625C6.875 16.4538 7.20424 17.2487 7.79029 17.8347C8.37634 18.4208 9.1712 18.75 10 18.75C10.8288 18.75 11.6237 18.4208 12.2097 17.8347C12.7958 17.2487 13.125 16.4538 13.125 15.625V15H17.5C17.6658 15 17.8247 14.9342 17.9419 14.8169C18.0592 14.6997 18.125 14.5408 18.125 14.375V12.5C18.125 12.3343 18.0591 12.1753 17.9419 12.0581L16.25 10.3662ZM11.875 15.625C11.875 16.1223 11.6775 16.5992 11.3258 16.9508C10.9742 17.3025 10.4973 17.5 10 17.5C9.50272 17.5 9.02581 17.3025 8.67417 16.9508C8.32254 16.5992 8.125 16.1223 8.125 15.625V15H11.875V15.625ZM16.875 13.75H7.13381L14.6324 6.2515C14.8749 6.84636 14.9998 7.48261 15 8.125V10.625C15 10.7907 15.0659 10.9497 15.1831 11.0669L16.875 12.7588V13.75Z"
          fill="#005FBE"
        />
        <path
          d="M4.81687 11.0669C4.93409 10.9497 4.99996 10.7907 5 10.625V8.125C5.00152 6.79938 5.52879 5.5285 6.46615 4.59115C7.4035 3.65379 8.67438 3.12652 10 3.125C11.1346 3.1256 12.2346 3.5157 13.1161 4.23006L14.0076 3.33863C13.0503 2.5311 11.8711 2.03196 10.625 1.90675V0.625H9.375V1.90675C7.83417 2.06352 6.4062 2.78605 5.36721 3.93462C4.32822 5.08319 3.75201 6.57622 3.75 8.125V10.3662L1.875 12.2412L2.75881 13.125L4.81687 11.0669Z"
          fill={theme.colors.text.secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_418_13929">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
