import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { Customer } from 'types';
import { ContainerState } from './types';

// The initial state of the CustomerEditor container
export const initialState: ContainerState = {
  customer: undefined,
  customerLoading: false,
  isProcessing: false,
  customerDeleted: false,
};

const customerEditorSlice = createSlice({
  name: 'customerEditor',
  initialState,
  reducers: {
    getCustomer(state: ContainerState, action: PayloadAction<string>) {
      return { ...state, customerLoading: true };
    },
    getCustomerSuccess(state, action: PayloadAction<Customer>) {
      return {
        ...state,
        customerLoading: initialState.customerLoading,
        isProcessing: initialState.isProcessing,
        customer: action.payload,
      };
    },

    clearCustomer(state: ContainerState, action: PayloadAction) {
      return { ...state, customer: undefined };
    },

    createCustomer(
      state,
      action: PayloadAction<{
        customer: Customer;
        logo?: File;
        redirectToEditCustomer: (customerId: string) => void;
      }>,
    ) {
      return {
        ...state,
        isProcessing: true,
      };
    },
    updateCustomer(
      state,
      action: PayloadAction<{
        id: string;
        updateObject: Customer;
        logo?: File;
      }>,
    ) {
      return {
        ...state,
        isProcessing: true,
      };
    },

    deleteCustomer(state, action: PayloadAction<string>) {
      return {
        ...state,
      };
    },
    deleteCustomerSucces(state, action: PayloadAction) {
      return {
        ...state,
        customer: undefined,
        customerDeleted: true,
      };
    },

    componentWillUnmount() {
      return initialState;
    },
  },
});

export const { actions, reducer, name: sliceKey } = customerEditorSlice;
