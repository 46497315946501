import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { translationString } from 'locales/translation';
import { useDispatch, useSelector } from 'react-redux';
import { OutputSelector } from 'reselect';
import { PageInfo, RootState } from 'types';

const pageSizeOptions = [10, 25, 50, 100, 200, 500];
export const defaultPageSize = 100;

type Props<T> = {
  selector: OutputSelector<
    RootState,
    PageInfo | undefined,
    (res: T) => PageInfo | undefined
  >;
  action: ActionCreatorWithPayload<{ page: number; pageSize: number }>;
};

function usePaginationRedux<T>({ selector, action }: Props<T>) {
  const dispatch = useDispatch();
  const pageInfo = useSelector(selector);

  const handlePageChange = (page: number) => {
    dispatch(
      action({
        page,
        pageSize: pageInfo?.pageSize || defaultPageSize,
      }),
    );
  };

  const handlePageSizeChange = (pageSize: number) => {
    dispatch(
      action({
        page: 1,
        pageSize,
      }),
    );
  };

  return {
    page: pageInfo?.page || 1,
    pageSize: pageInfo?.pageSize || defaultPageSize,
    pageCount: pageInfo?.pageCount,
    totalRecords: pageInfo?.totalRecords,
    pageSizeOptions,
    onChange: handlePageChange,
    onChangePageSize: handlePageSizeChange,
    renderShow: () => translationString('Pagination.Show'),
    renderPageCount: (page, pageCount) =>
      translationString('Pagination.Page', {
        page,
        pageCount,
      }),

    renderTotalRecords: totalRecords =>
      translationString('Pagination.Records', {
        totalRecords,
      }),
  };
}

export default usePaginationRedux;
