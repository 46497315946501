import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { MaintainableItem, RootState, TaskTypes } from 'types';
import { selectMiId } from '../EquipmentDetail/selectors';
import { initialState } from './slice';
import {
  getSortedInspectionFields,
  getInspectionTaskTypeId,
  separateInspectionFields,
  hydrateInspections,
} from './utils';

const selectDomain = (state: RootState) =>
  state.equipmentInspection || initialState;

export const selectInspectionsPayload = createSelector(
  [selectDomain, selectMiId],
  (equipmentInspection, miId) => {
    const { pageInfo, sort } = equipmentInspection.inspections;

    return {
      miId,
      pageIndex: pageInfo?.page,
      pageSize: pageInfo?.pageSize,
      sortField: sort?.columnId || 'title',
      sortOrder: sort?.sortDirection || 'DESC',
      taskTypes: [TaskTypes.INSPECTION],
    };
  },
);

export const selectAddingStatus = createSelector(
  [selectDomain],
  ({ isAdding, addingStatus }) => ({ isAdding, addingStatus }),
);

export const selectInspections = createSelector(
  [selectDomain],
  ({ inspections }) => inspections,
);

export const selectInspectionsLoading = createSelector(
  [selectInspections],
  ({ loading }) => loading,
);

export const selectInspectionsSort = createSelector(
  [selectInspections],
  ({ sort }) => sort,
);

export const selectInspectionsPageInfo = createSelector(
  [selectInspections],
  ({ pageInfo }) => pageInfo,
);

export const selectMetadataDomain = createSelector(
  [selectDomain],
  ({ metadata }) => metadata,
);

export const selectMetadata = createSelector(
  [selectMetadataDomain],
  ({ data, loading }) => ({ metadata: data, isLoading: loading }),
);

export const selectInspectionTaskTypeId = createSelector(
  [selectMetadata],
  ({ metadata }) => getInspectionTaskTypeId(metadata),
);

export const selectInspectionFields = createSelector(
  [selectMetadata],
  ({ metadata }) => (metadata ? getSortedInspectionFields(metadata) : []),
);

export const selectSeparatedInspectionFields = (
  equipments: MaintainableItem[],
) =>
  createSelector([selectInspectionFields], fields =>
    separateInspectionFields(fields, equipments),
  );

export const selectInspectionsHydrated = createSelector(
  [selectInspections, selectInspectionFields],
  ({ data: inspections }, fields) =>
    inspections ? hydrateInspections(inspections, fields) : [],
);

export const selectInspectionsSorted = createSelector(
  [selectInspectionsHydrated],
  inspections => sortBy(inspections, 'inspectionDate'),
);
