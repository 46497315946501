import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { HomeIcon } from '@eriksdigital/atomic-ui/components/Icons';
import styled, { css } from 'styled-components';

import { ReactComponent as DocumentsIcon } from 'assets/icons/documents.svg';
import { ReactComponent as WorkOrdersIcon } from 'assets/icons/work_orders.svg';
import { translationString } from 'locales/translation';
import { useInjectReducer } from 'utils/redux-injectors';
import routeHelpers from 'utils/route-helpers';
import { matchMedia, mediaQuery } from 'utils/mediaQuery';
import MenuAssets from './components/MenuAssets';
import MenuEquipment from './components/MenuEquipment';
import MenuItem from './components/MenuItem';
import MenuSettings from './components/MenuSettings';
import { selectMenuIsOpen, selectSubMenuIsOpen } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import CreateEquipmentTypeModal from './components/CreateEquipmentTypeModal';
import {
  selectSelectedCustomer,
  selectSelectedLocation,
} from '../CustomerLocation/selectors';

function SideMenu() {
  useInjectReducer({ key: sliceKey, reducer: reducer });

  const isMenuOpen = useSelector(selectMenuIsOpen);
  const isSubMenuOpen = useSelector(selectSubMenuIsOpen());
  const customerId = useSelector(selectSelectedCustomer);
  const locationId = useSelector(selectSelectedLocation);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.close());
  }, [location, dispatch]);

  useEffect(() => {
    if (matchMedia.phoneLarge.matches) {
      return;
    }

    if (isMenuOpen) {
      document.documentElement.style.setProperty('overflow', 'hidden');
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
      document.documentElement.style.removeProperty('overflow');
    }
  }, [isMenuOpen]);

  const ABTestCustomers = [
    '576a83be-16f7-43e4-929c-6769501267aa', // BritishGypsum Dev
    'd4bcde24-bc04-494a-8fe4-a0f386f7566c', // ERIKS UK Demo Acc/Prod
    '85dc4f03-7cf3-44ef-a5a5-3852d912cd8e', // ED QA Automation
    '3d06f50a-4c4c-4ce1-a482-4db21c0452f5', // ERIKS UK Demo Dev
    'c03b1cb3-4afb-4fca-9efd-597febb5b618',
    'b3be2c9b-0caf-4b93-8b6c-c3ee31aa85d0', // Friesland Campina (Prod)

    // UK
    '46086bd4-931c-42c5-bee4-f94b65e1188c',
    'c4171563-4b18-43e7-ac69-83482b6ac040',
    '562f36e9-5125-4af2-bb3a-bf64b9fa48cf',
    'e2f92942-4d49-40ea-bc04-f8f958afa00f',
    // DE
    '3fe2d14f-b950-46c5-9f67-3831be31c1f9', // ADM WILD Europe GmbH & Co. KG - Werk Berlin
    '42f40ff6-d254-4f5b-ae61-041bac06f351', // Airbus Aerostructures GmbH
    'ff92b29b-6d65-4078-9b7b-3386b241b76a', // Daimler Truck, Wörth
    'debe0b36-94c5-452a-90be-d8c4f566bbcc', // Daimler Truck AG - Stuttgart
    'ff92b29b-6d65-4078-9b7b-3386b241b76a', // Daimler Truck, Wörth
    '96fff710-41eb-4b71-8943-024061416189', // Dr. Kurt Wolff GmbH & Co. KG
    'f7e14219-4f1d-437b-bdf2-5545ced9c115', // Henkel AG & Co.KGaA
    '512ea1b2-ac52-47bc-a15c-96a6a8abab24', // Linde GmbH
    'c4d46ee7-d8a1-46ca-ac68-ed3c1bfa76f8', // Mercedes-Benz AG
    'e9919e85-0fd4-4fbe-ae5d-ff092dbd1393', // Mercedes-Benz AG VAN
    '32241235-7a30-4f30-991c-8f1b73ff4a6a', // PPG Wörwag Coatings GmbH & Co. KG
    // FR
    '99623dd3-7b37-4093-a3bb-95a61e8b97bc', //ERIKS France
    '8afddb4c-e11f-493a-8189-d95d89b5e46d', //FIRMENICH - Grasse
    '2ce1672a-281f-4727-bb3e-1bfbe3e39b5c', //LAMESTA FINE
    '42330daf-60ee-45f9-8e17-16e20ca752fe', //LANXESS - Epierre
    '5046d9aa-3b95-4178-97c3-1af8ccd29816', //SERGE FERRARI
    '1992e35e-0f9d-4177-afd0-dd6f7cb1dcae', // HEXCEL fibers - Roussillon
    '01505359-b6ce-4cac-a4f0-4c56179f687b', // SEQENS - Roussillon
  ];

  return (
    <SideMenuContainer isMenuOpen={isMenuOpen} isSubMenuOpen={isSubMenuOpen}>
      <Menu>
        {locationId && ABTestCustomers.includes(customerId) && (
          <MenuItem
            to={routeHelpers.buildMainDashboardRoute(customerId, locationId)}
            icon={HomeIcon}
            label={translationString('Dashboard')}
          />
        )}

        <MenuAssets />
        {ABTestCustomers.includes(customerId) && <MenuEquipment />}
        <MenuItem
          to={routeHelpers.buildDashboardTasksRoute(customerId, locationId)}
          icon={WorkOrdersIcon}
          label={translationString('Tasks')}
        />
        {/* <MenuItem to="/" icon={QuoteIcon} label={translationString('Quotes')} /> */}
        <MenuItem
          to={routeHelpers.buildDashboardAttachmentsRoute(
            customerId,
            locationId,
          )}
          icon={DocumentsIcon}
          label={translationString('Attachments.Attachments')}
        />
        <MenuSettings />
      </Menu>
      <CreateEquipmentTypeModal />
    </SideMenuContainer>
  );
}

export default withRouter(SideMenu);

const SideMenuContainer = styled.nav<{
  isMenuOpen: boolean;
  isSubMenuOpen: boolean;
}>`
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 0;
  background-color: ${({ theme }) => theme.colors.default.blue1};
  z-index: 2;
  transition: max-width 300ms;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      max-width: 100vw;
    `}

  ${({ isSubMenuOpen }) =>
    isSubMenuOpen &&
    css`
      max-width: 0;
    `}

  ${mediaQuery.phoneLarge} {
    top: 80px;
    width: 96px;
    max-width: unset;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
`;
