import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Icon, Button } from '@eriksdigital/atomic-ui/components';
import {
  TrashBinIcon,
  EditIcon,
} from '@eriksdigital/atomic-ui/components/Icons';

import t, { translationString } from 'locales/translation';
import { Task, TaskReq, PreviousPath } from 'types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { decodeHTML } from 'utils/decodeHtml';
import { buildDashboardTasksRoute } from 'utils/route-helpers';
import { useAuth, useUserDetails } from 'app/providers/AuthProvider';
import { TAM, CAM, FAM } from 'app/providers/AuthProvider/roles';
import { Modal } from 'app/components/Modal';
import { TaskEditor } from 'app/components/TaskEditor';
import { ConfirmDialog } from 'app/components/ConfirmDialog';
import { BackNavigation } from 'app/components/BackNavigation';
import { useCustomerLocation } from '../CustomerLocation';
import { Notification, StopNotification } from 'assets/icons';

import { reducer, sliceKey, actions } from './slice';
import { selectTask } from './selectors';
import { taskDetailsSaga } from './saga';
import { Details } from './Details';
import { getTaskItem } from './utils';
import DownloadCertificateButton from './DownloadCertificateButton';

export function TaskDetails() {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: taskDetailsSaga });

  useCustomerLocation({
    hasLocationSelector: false,
    replacePath: '/admin/customer/:customerId',
  });

  const history = useHistory();
  const prevPath = useLocation().state as PreviousPath;

  const dispatch = useDispatch();
  const { customerId, locationId, taskId } = useParams<{
    customerId: string;
    locationId: string;
    taskId: string;
  }>();

  const { task } = useSelector(selectTask);
  const { userHasRole } = useAuth();
  const user = useUserDetails();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteTask, setDeleteTask] = useState<Task | undefined>();

  useEffect(() => {
    if (taskId && !task) {
      dispatch(actions.getTask(taskId));
    }
  }, [dispatch, task, taskId]);

  const handleNotificationToggle = () => {
    if (task) {
      if (task.notifyMe) {
        dispatch(actions.stopNotifications(task.id));
      } else {
        dispatch(actions.getNotifications(task.id));
      }
    }
  };

  const handleDeleteTask = () => {
    if (deleteTask) {
      dispatch(actions.deleteTask(deleteTask.id));
      history.push({
        pathname:
          prevPath?.prevPath ||
          buildDashboardTasksRoute(customerId, locationId),
        search: prevPath?.prevSearch,
      });
    }
  };

  const canEditOrDelete = (task: Task) => {
    return (
      userHasRole([TAM, CAM, FAM]) ||
      task.assignee?.id === user?.id ||
      task.createdBy?.id === user?.id
    );
  };

  const renderActionButtons = (task: Task) => (
    <Actions>
      {task.taskType.mainType === 'INSPECTION' && (
        <DownloadCertificateButton task={task} />
      )}

      {userHasRole([TAM, CAM, FAM]) && (
        <Button
          id="notifications"
          variant="ghost"
          onClick={handleNotificationToggle}
          leftIcon={
            <Icon as={task.notifyMe ? StopNotification : Notification} />
          }
        >
          {task.notifyMe
            ? translationString('StopNotifications')
            : translationString('GetNotifications')}
        </Button>
      )}

      {canEditOrDelete(task) && (
        <Button
          id="edit"
          variant="ghost"
          onClick={() => setOpenEditModal(true)}
          leftIcon={<Icon as={EditIcon} />}
        >
          {translationString('Edit')}
        </Button>
      )}

      {userHasRole([TAM, CAM, FAM]) && (
        <Button
          id="delete"
          variant="ghost"
          onClick={() => setDeleteTask(task)}
          leftIcon={<Icon as={TrashBinIcon} />}
        >
          {translationString('Delete')}
        </Button>
      )}
    </Actions>
  );

  return (
    <Wrapper>
      <Header>
        <BackNavigation
          label={prevPath ? 'BackToPage' : 'BackToOverview'}
          name={prevPath?.prevName || translationString('Task')}
          link={
            prevPath
              ? prevPath.prevPath + prevPath.prevSearch
              : buildDashboardTasksRoute(customerId, locationId)
          }
        />

        {task && renderActionButtons(task)}
      </Header>

      {task && <Details task={task} item={getTaskItem(task)} />}

      {task && openEditModal && (
        <Modal
          onClose={() => setOpenEditModal(false)}
          withOutsideClose
          maxWidth={1200}
        >
          <TaskEditor
            item={getTaskItem(task)}
            task={task}
            relatedAlertId={task.relatedAlertId}
            onSubmit={(updatedTask: TaskReq) => {
              dispatch(actions.updateTask(updatedTask));
              setOpenEditModal(false);
            }}
            onCancel={() => setOpenEditModal(false)}
          />
        </Modal>
      )}

      {deleteTask && (
        <ConfirmDialog
          open={true}
          title={t('DeleteTask')}
          message={
            <span
              data-stringid="ConfirmDeleteTask"
              dangerouslySetInnerHTML={{
                __html: decodeHTML(
                  translationString('ConfirmDeleteTask', {
                    task: deleteTask.title,
                  }),
                ),
              }}
            />
          }
          onConfirm={handleDeleteTask}
          onCancel={() => setDeleteTask(undefined)}
          confirmText={translationString('Yes')}
          cancelText={translationString('No')}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-direction: column;
  padding: 12px;

  ${({ theme }) => theme.breakpoints.tabletLandscape`
    padding: 24px 32px;
  `};

  overflow-y: auto;
  max-height: 100vh;
`;

const Header = styled.div`
  display: block;
  gap: 16px;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.tabletLandscape`
    display: flex;
    justify-content: space-between;
  `};
`;

const Actions = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sp16};
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.sp16};

  button {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  }
`;
