import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Icon, Button } from '@eriksdigital/atomic-ui/components';
import { DownloadIcon } from '@eriksdigital/atomic-ui/components/Icons';
import { Task } from 'types';
import { translationString } from 'locales/translation';
import { downloadCertificate } from 'services/fileManagementServices';
import { selectUserDetails } from 'app/providers/AuthProvider/selectors';
import { downloadBlob } from 'utils/download';
import { actions as announcementActions } from '../AnnouncementsToast/slice';

type Props = {
  task: Task;
};

function DownloadCertificateButton({ task }: Props) {
  const dispatch = useDispatch();
  const { customerId } = useParams<{ customerId: string }>();
  const user = useSelector(selectUserDetails);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClick = async () => {
    const { locationId, maintainableItemId, id: taskId } = task;

    if (!maintainableItemId || !user?.language) {
      return;
    }

    setIsProcessing(true);

    try {
      const response = await downloadCertificate({
        customerId,
        locationId,
        maintainableItemId,
        taskId,
        language: user?.language,
      });
      downloadBlob(response);
    } catch (e) {
      dispatch(announcementActions.addError((e as Error).message));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Button
      id="download-certificate"
      variant="ghost"
      onClick={handleClick}
      leftIcon={<Icon as={DownloadIcon} />}
      disabled={isProcessing}
    >
      {translationString('Resources.Actions.Download', {
        filename: translationString('ResourceTypes.CERTIFICATE'),
      })}
    </Button>
  );
}

export default DownloadCertificateButton;
