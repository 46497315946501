import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import InputFile from 'app/elements/Fields/InputFile';
import t, { translationString } from 'locales/translation';
import Label from 'app/elements/Fields/Label';
import Field from 'app/elements/Fields/Field';
import SideForm from 'app/components/SideForm';
import useStagingFiles from 'app/containers/EquipmentAttachments/hooks/useStagingFiles';
import StagedFileForm from 'app/containers/EquipmentAttachments/components/StagedFileForm';
import { ResourcePayload } from 'app/containers/EquipmentAttachments/types';
import { buildResourcePayload } from 'app/containers/EquipmentAttachments/utils';
import useEquipmentAttachments from '../hooks/useEquipmentAttachments';

const MAX_FILES_UPLOAD = 10;
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

type Props = {
  onSubmit: (payload: ResourcePayload[]) => void;
  onClose: () => void;
  intro?: ReactNode;
};

function EquipmentAttachmentsForm({ onSubmit, onClose, intro }: Props) {
  const { fetchResourceTypes } = useEquipmentAttachments();
  const { files, handleDeleteFile, handleChangeInputFile } = useStagingFiles();
  const [validationMessage, setValidationMessage] = useState<
    string | undefined
  >(undefined);
  const [fileExceedsLimit, setFileExceedsLimit] = useState<boolean>(false);

  useEffect(() => {
    fetchResourceTypes();
  }, [fetchResourceTypes]);

  useEffect(() => {
    setValidationMessage(
      files.length > MAX_FILES_UPLOAD
        ? translationString('MaxFilesUpload', { maxFiles: MAX_FILES_UPLOAD })
        : undefined,
    );
  }, [files, setValidationMessage]);

  useEffect(() => {
    setFileExceedsLimit(files.some(file => file.file.size > MAX_FILE_SIZE));
  }, [files]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const payload = buildResourcePayload(formData, files);

    onSubmit(payload);
  };

  return (
    <SideForm
      intro={intro}
      confirm={t('Attachments.Upload')}
      onSubmit={handleSubmit}
      onCancel={onClose}
      isConfirmDisabled={
        !files.length || !!validationMessage || fileExceedsLimit
      }
    >
      <>
        <Field>
          <Label htmlFor="fileUpload">{t('Attachments.Select')}</Label>
          <InputFile
            id="fileUpload"
            multiple
            title={translationString('Attachments.Select')}
            onChange={handleChangeInputFile}
          />
        </Field>
        {!!files.length && (
          <Files>
            {files.map(file => (
              <StagedFileForm
                key={`${file.id}`}
                file={file}
                onDelete={handleDeleteFile}
                fileLimitSize={MAX_FILE_SIZE}
              />
            ))}
          </Files>
        )}
        {validationMessage && (
          <ValidationMessage>{validationMessage}</ValidationMessage>
        )}
      </>
    </SideForm>
  );
}

const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ValidationMessage = styled.span`
  color: ${({ theme }) => theme.colors.default.red};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
`;

export default EquipmentAttachmentsForm;
