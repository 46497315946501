import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import { translationString } from 'locales/translation';
import apiCall from 'services/utils/apiCall';
import { parseAxiosError } from 'utils/error-helpers';
import { getCustomers as getCustomersApi } from 'services/customersService';
import { getCustomerLocations as getCustomerLocationsApi } from 'services/customersService';
import { actions } from './slice';
import {
  selectHasLocationSelector,
  selectLocations,
  selectPath,
  selectSelectedCustomer,
  selectSelectedLocation,
} from './selectors';
import { buildRedirectPath } from './utils';

export function* getCustomers() {
  try {
    const { data: customers } = yield apiCall(getCustomersApi);
    const sortedCustomers = customers.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    yield put(actions.getCustomersSuccess(sortedCustomers));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.Customers'),
    );
    yield put(actions.getCustomersFailure(effectError));
  }
}

export function* getCustomerLocations(action: PayloadAction<string>) {
  const customerId: string = action.payload;

  try {
    const { data: customerLocations } = yield apiCall(
      getCustomerLocationsApi,
      customerId,
    );
    yield put(actions.getCustomerLocationsSuccess(customerLocations));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.LocationsRetrievingError'),
    );
    yield put(actions.getCustomerLocationsFailure(effectError));
  }
}

export function* redirect() {
  const customerId = yield select(selectSelectedCustomer);
  const locationId = yield select(selectSelectedLocation);
  const path = yield select(selectPath);
  const hasLocationSelector = yield select(selectHasLocationSelector);
  const redirectTo = hasLocationSelector
    ? buildRedirectPath(path, customerId, locationId)
    : buildRedirectPath(path, customerId);
  yield put(push(redirectTo));
}

export function* setSelectedCustomer(action: PayloadAction<string>) {
  yield call(getCustomerLocations, action);
  const locations = yield select(selectLocations);
  const locationId = yield select(selectSelectedLocation);
  if (!locationId) {
    yield put(actions.setSelectedLocation(locations[0].id));
  }
}

export function* changeSelectedCustomer(action: PayloadAction<string>) {
  yield call(getCustomerLocations, action);
  const locations = yield select(selectLocations);
  yield put(actions.setSelectedLocation(locations[0].id));
  yield call(redirect);
}

export function* changeSelectedLocation(action: PayloadAction<string>) {
  yield call(redirect);
}

export function* customerControlsSaga() {
  yield takeLatest(actions.getCustomers.type, getCustomers);
  yield takeLatest(actions.getCustomerLocations.type, getCustomerLocations);
  yield takeLatest(actions.setSelectedCustomer.type, setSelectedCustomer);
  yield takeLatest(actions.changeSelectedCustomer.type, changeSelectedCustomer);
  yield takeLatest(actions.changeSelectedLocation.type, changeSelectedLocation);
}
