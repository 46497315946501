import { ExportResponse } from 'types';

export function downloadBlob(
  response: ExportResponse,
  fileName = getFileName(response),
) {
  const { data } = response;
  const url = URL.createObjectURL(new Blob([data]));

  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();

  URL.revokeObjectURL(url);
}

function getFileName({ headers }: ExportResponse) {
  return headers['content-disposition'].split('=')[1];
}
