import {
  Severity,
  TaskStatus,
  BgColor,
  TextColor,
  IconColor,
  CertificateStatus,
} from 'types';

export const getTextColor = (theme: any, color?: TextColor): string => {
  if (color === TextColor.primary) return theme.colors.text.primary; //  #000
  if (color === TextColor.inverted) return theme.colors.text.inverted; // #fff
  if (color === TextColor.secondary) return theme.colors.text.secondary; // #005ca9
  if (color === TextColor.tertiary) return theme.colors.text.tertiary; // #19335e
  if (color === TextColor.muted) return theme.colors.grays.darkGray; // #646972
  if (color === TextColor.primaryNew) return theme.colors.default.blue1; // #005fbe
  if (color === TextColor.secondaryNew) return theme.colors.default.blue2; // #002c4e
  if (color === TextColor.red) return theme.colors.default.red; // #d61c1c

  return 'inherit';
};

export const getBGColor = (theme: any, bg?: BgColor): string => {
  if (bg === BgColor.white) return theme.colors.background.white; //  #fff
  if (bg === BgColor.gray) return '#C1C7CD';
  if (bg === BgColor.lighterGray) return '#f2f4f8';
  if (bg === BgColor.lightGray) return theme.colors.grays.lightGray; // #f8f8f8
  if (bg === BgColor.darkGray) return theme.colors.grays.darkGray; // ##646972
  if (bg === BgColor.lightBlue) return theme.colors.background.tertiary; // #d4f0fa
  if (bg === BgColor.darkBlue) return theme.colors.default.blue1; // #005fbe

  return 'inherit';
};

export const getIconColor = (theme: any, bg?: IconColor): string => {
  if (bg === IconColor.green) return theme.colors.text.proceed; // #009f47
  if (bg === IconColor.red) return theme.colors.default.red; // #d61c1c
  if (bg === IconColor.orange) return theme.colors.signal.warning; // #d99300

  return 'inherit';
};

export const getColorForSeverity = (severity: Severity, theme: any): string => {
  const severityLC = severity.toLocaleLowerCase();

  if (severityLC === Severity.GOOD) return theme.colors.signal.success;
  if (severityLC === Severity.CRITICAL) return theme.colors.signal.alert;
  if (severityLC === Severity.WARNING) return '#B47A00';
  if (severityLC === Severity.INFO) return '#19335E';
  if (severityLC === Severity.UNKNOWN) return theme.colors.grays.darkGray;

  return theme.colors.grays.darkGray;
};

export const getFillColorForSeverity = (severity: Severity) => {
  const severityLC = severity.toLocaleLowerCase();

  if (severityLC === Severity.GOOD) return '#CCECDA';
  if (severityLC === Severity.CRITICAL) return '#F7D2D2';
  if (severityLC === Severity.WARNING) return '#F7E9CC';
  if (severityLC === Severity.UNKNOWN) return '#D3D3D3';
};

export const getColorForCertificateStatus = (status: CertificateStatus) => {
  if (status === CertificateStatus.VALID) return '#D6EFE1';
  if (status === CertificateStatus.ALMOST_EXPIRED) return '#F7E9CC';
  if (status === CertificateStatus.EXPIRED) return '#F8DADA';
  if (status === CertificateStatus.FAILED) return '#F8DADA';
  if (status === CertificateStatus.NOT_INSPECTED) return '#F2F4F8';

  return '#F2F4F8';
};

export const getBGForSeverity = (severity: Severity, theme: any): string => {
  const severityLC = severity.toLocaleLowerCase();

  if (severityLC === Severity.GOOD) return '#cce6d8';
  if (severityLC === Severity.CRITICAL) return theme.colors.background.alert;
  if (severityLC === Severity.WARNING) return '#fef4cd';
  if (severityLC === Severity.INFO) return theme.colors.background.tertiary;
  if (severityLC === Severity.UNKNOWN) return theme.colors.background.white;

  return theme.colors.background.white;
};

export const getColorForStatus = (status: TaskStatus, theme: any): string => {
  if (status === TaskStatus.TODO) return theme.colors.background.primary;
  if (status === TaskStatus.DONE) return '#02803A';

  return theme.colors.grays.darkGray;
};

export const getBGForStatus = (status: TaskStatus, theme: any): string => {
  if (status === TaskStatus.TODO) return '#D4F0FA';
  if (status === TaskStatus.DONE) return 'rgba(2, 128, 58, 0.2)';

  return '#C8CCD6';
};
