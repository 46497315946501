import React from 'react';
import Field from './Field';
import Label from './Label';
import { Input } from './withValidation';

export default (props: React.InputHTMLAttributes<HTMLInputElement>) => (
  <Field>
    <Label htmlFor={props.name} required={props.required}>
      {props['aria-label']}
    </Label>
    <Input {...props} id={props.id || props.name} />
  </Field>
);
