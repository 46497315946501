import { createSelector } from '@reduxjs/toolkit';

import { deepFindSpaceById } from 'app/containers/AssetsDashboard/utils/deepFindSpace';
import { hasChildren } from 'app/containers/AssetsDashboard/utils/hasChildren';
import { deepGetAssets } from 'app/containers/AssetsDashboard/utils/deepGetAssets';
import { HierarchyNode, RootState } from 'types';
import { initialState } from './slice';
import { applyAssetFilters, assetSort } from './utils/filters';

const selectDomain = (state: RootState) =>
  state.assetsDashboard || initialState;

export const selectAssetsNavigator = createSelector(
  [selectDomain],
  assetsNavigatorState => assetsNavigatorState,
);

export const selectHierarchy = createSelector(
  [selectDomain],
  ({ hierarchy, hierarchyLoading, hierarchyError }) => ({
    tree: hierarchy,
    loading: hierarchyLoading,
    error: hierarchyError,
  }),
);

export const selectMaintainableItems = createSelector(
  [selectDomain],
  ({ maintainableItems }) => maintainableItems,
);

export const selectHierarchyLoading = createSelector(
  [selectDomain],
  ({ hierarchyLoading }) => hierarchyLoading,
);

export const selectHierarchyTableAssets = createSelector(
  [selectDomain, (_, spaceId: string = '') => spaceId],
  (
    {
      hierarchy,
      assetFilterStatus,
      assetFilterSearch,
      assetFilterIds,
      assetSortField,
      assetSortDirection,
    },
    spaceId,
  ) => {
    if (!hierarchy || !hasChildren(hierarchy)) {
      return [];
    }

    let assets: HierarchyNode[] = [];
    if (spaceId.length) {
      const spaceHierarchy = deepFindSpaceById(hierarchy.children, spaceId);
      if (spaceHierarchy || hasChildren(spaceHierarchy)) {
        assets = deepGetAssets(spaceHierarchy.children) || [];
      }
    } else {
      assets = deepGetAssets(hierarchy?.children) || [];
    }

    if (!assets?.length) {
      return [];
    }
    return applyAssetFilters(
      assets,
      assetFilterStatus,
      assetFilterSearch,
      assetFilterIds,
    ).sort((a, b) => assetSort(a, b, assetSortField, assetSortDirection));
  },
);

export const selectAllAssets = createSelector(
  [selectDomain],
  ({ hierarchy }) => {
    if (!hierarchy || !hasChildren(hierarchy)) {
      return [];
    }
    return deepGetAssets(hierarchy?.children);
  },
);

export const selectLocationName = createSelector(
  [selectDomain],
  ({ hierarchy }) => {
    if (!hierarchy) return '';
    return hierarchy.name;
  },
);

export const selectSpaceName = createSelector(
  [selectDomain, (_, spaceId: string) => spaceId],
  ({ hierarchy }, spaceId) => {
    if (!hierarchy || !hasChildren(hierarchy)) {
      return [];
    }
    const spaceHierarchy = deepFindSpaceById(hierarchy.children, spaceId);

    if (!spaceHierarchy) {
      return '';
    }

    return spaceHierarchy.name;
  },
);

export const selectCustomerHierarchy = customerId =>
  createSelector([selectDomain], ({ customerHierarchies }) =>
    customerHierarchies.find(ch => ch.id === customerId),
  );

export const selectCustomerHierarchyLoading = createSelector(
  [selectDomain],
  ({ customerHierarchiesLoading }) => customerHierarchiesLoading,
);

export const selectCustomerHierarchyError = createSelector(
  [selectDomain],
  ({ customerHierarchiesError }) => customerHierarchiesError,
);

export const selectAssetFilterSearch = createSelector(
  [selectDomain],
  ({ assetFilterSearch }) => assetFilterSearch,
);

export const selectAssetFilterStatus = createSelector(
  [selectDomain],
  ({ assetFilterStatus }) => assetFilterStatus,
);

export const selectAssetFilterIDs = createSelector(
  [selectDomain],
  ({ assetFilterIds }) => assetFilterIds,
);

export const selectSortField = createSelector(
  [selectDomain],
  ({ assetSortField }) => assetSortField,
);

export const selectActiveTreeNodeId = createSelector(
  [selectDomain],
  ({ activeTreeNodeId }) => activeTreeNodeId,
);
