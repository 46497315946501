import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.equipmentAttachments || initialState;

export const selectUploadStatus = createSelector(
  [selectDomain],
  ({ isUploading, uploadingStatus }) => ({ isUploading, uploadingStatus }),
);

export const selectDocuments = createSelector([selectDomain], ({ data }) =>
  Array.isArray(data) ? data : [],
);

export const selectDocumentsSorted = createSelector(
  [selectDocuments],
  documents => sortBy(documents, 'reportDate'),
);

export const selectIsLoadingDocuments = createSelector(
  [selectDomain],
  ({ loading }) => loading,
);

export const selectResourceTypes = createSelector(
  [selectDomain],
  ({ resourceTypes }) => resourceTypes,
);
