import { breakpointObj as breakpoints } from '@eriksdigital/atomic-ui/styles';

const getBreakpoints = <T>(
  callback: (breakpoint: number) => T,
): {
  custom: (breakpoint: number) => T;
  phoneLarge: T;
  tabletPortrait: T;
  tabletIpadPortrait: T;
  tabletLandscape: T;
  desktop: T;
  giant: T;
} => ({
  custom: callback,
  phoneLarge: callback(breakpoints.phoneLarge),
  tabletPortrait: callback(breakpoints.tabletPortrait),
  tabletIpadPortrait: callback(breakpoints.tabletIpadPortrait),
  tabletLandscape: callback(breakpoints.tabletLandscape),
  desktop: callback(breakpoints.desktop),
  giant: callback(breakpoints.giant),
});

export const mediaQuery = getBreakpoints(
  (minWidth: number) => `@media (min-width: ${minWidth}px)`,
);

export const mediaQueryMax = getBreakpoints(
  (maxWidth: number) => `@media (max-width: ${maxWidth}px)`,
);

export const matchMedia = getBreakpoints((minWidth: number) =>
  window.matchMedia(`(min-width: ${minWidth}px)`),
);

export const matchMediaString = getBreakpoints(
  (minWidth: number) => `(min-width: ${minWidth}px)`,
);
