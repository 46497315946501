import React, { ReactNode, useState } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { CrossIcon, EditIcon } from '@eriksdigital/atomic-ui/components/Icons';
import { Div } from 'app/components/Div';
import { H1 } from 'app/components/Typography';
import { ButtonIcon } from 'app/elements/Buttons';
import { mediaQuery } from 'utils/mediaQuery';

type Props = {
  title: ReactNode;
  render: (handleClose: () => void) => ReactNode;
  onClose: () => void;
  onEdit?: () => void;
  locked?: boolean;
};

export const SlidingSideBar = ({
  title,
  render,
  onClose,
  onEdit,
  locked = false,
}: Props) => {
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const handleClose = () => {
    if (!locked) {
      setIsClosing(true);
      setTimeout(() => onClose(), 300);
    }
  };

  return (
    <Container>
      <Overlay isClosing={isClosing} onClick={handleClose} />
      <SideBar isClosing={isClosing}>
        <ContentHeader>
          <H1>{title}</H1>
          <Div display="flex" justifyContent="flex-end" gap="8px">
            {onEdit && (
              <ButtonIcon icon={EditIcon} size="sz20" onClick={onEdit} />
            )}
            <ButtonIcon icon={CrossIcon} size="sz24" onClick={handleClose} />
          </Div>
        </ContentHeader>
        <ContentBody>{render(handleClose)}</ContentBody>
      </SideBar>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.stacking.modal};
`;

const slideIn = keyframes`
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
`;

const slideOut = keyframes`
  from {
    right: 0;
  }
  to {
    right: -100vw;
  }
`;

const SideBar = styled.div<{ isClosing: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.background.white};
  animation: ${slideIn} 400ms forwards;
  padding-top: 24px;
  box-shadow: -3px 0px 6px 0px rgba(0, 0, 0, 0.1);

  ${({ isClosing }) =>
    isClosing &&
    css`
      animation: ${slideOut} 400ms forwards;
    `}

  ${mediaQuery.phoneLarge} {
    width: fit-content;
  }

  ${mediaQuery.desktop} {
    max-width: 50vw;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

const ContentBody = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 24px;

  &::-webkit-scrollbar {
    appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    border-radius: 8px;
  }
`;

const Overlay = styled.div<{ isClosing: boolean }>`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.overlay.dim};
  opacity: 0.4;
  transition: opacity 300ms;

  ${({ isClosing }) =>
    isClosing &&
    css`
      opacity: 0;
    `}
`;
