import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import { translationString } from 'locales/translation';
import {
  deleteResource,
  getResourceTypesForAssetType,
  getResourcesForEntity,
  putResource,
} from 'services/fileManagementServices';
import apiCall from 'services/utils/apiCall';
import { EntityType, UpdateResourceObject } from 'types';
import { notifyAxiosError } from 'utils/error-helpers';
import { postEquipmentResource } from '../EquipmentAttachments/utils';
import { selectMiId } from '../EquipmentDetail/selectors';
import { actions } from './slice';
import { selectResourceTypes } from './selectors';
import { UploadResourcePayload } from './types';

export function* getDocumentsAndCertificates() {
  try {
    const miId = yield select(selectMiId);
    const resourceTypes = yield select(selectResourceTypes);
    const { data } = yield apiCall(getResourcesForEntity, miId, {
      entityType: EntityType.MAINTAINABLE_ITEM,
      resourceTypes,
    });
    yield put(actions.getDocumentsAndCertificatesSuccess(data));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.DocumentsAndCertificatesError'),
    );

    yield put(actions.getDocumentsAndCertificatesError(effectError));
  }
}

export function* getResourceTypes() {
  try {
    const resourceTypes = yield select(selectResourceTypes);
    if (!resourceTypes.length) {
      const { data } = yield apiCall(
        getResourceTypesForAssetType,
        EntityType.MAINTAINABLE_ITEM,
      );
      yield put(actions.getResourceTypesSuccess(data));
    } else {
      yield put(actions.getResourceTypesSkipped());
    }
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.ResourceTypes'),
    );
    yield put(actions.getResourceTypesFailure(effectError));
  }
}

export function* editDocumentsAndCertificates(
  action: PayloadAction<{
    entityId: string;
    resourceId: string;
    updateObject: UpdateResourceObject;
  }>,
) {
  try {
    yield apiCall(
      putResource,
      action.payload.updateObject,
      action.payload.entityId,
      action.payload.resourceId,
    );

    yield put(actions.getDocumentsAndCertificates());

    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.UpdatedSuccessfully', {
          description: action.payload.entityId,
        }),
      ),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UpdateEntity.Resource'),
    );
    yield put(
      actions.editDocumentsAndCertificatesFailure({
        ...action.payload,
        error: effectError,
      }),
    );
  }
}
export function* deleteDocumentsAndCertificates(
  action: PayloadAction<{
    entityId: string;
    resourceId: string;
  }>,
) {
  try {
    yield apiCall(
      deleteResource,
      action.payload.entityId,
      action.payload.resourceId,
    );
    yield put(actions.getDocumentsAndCertificates());
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.DeletedSuccessfully', {
          description: action.payload.entityId,
        }),
      ),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.DeleteEntity.Resource'),
    );
    yield put(
      actions.deleteDocumentsAndCertificatesFailure({
        entityId: action.payload.entityId,
        error: effectError,
      }),
    );
  }
}

export function* uploadAttachments(
  action: PayloadAction<UploadResourcePayload>,
) {
  try {
    const { customerId, locationId, equipmentId, attachments } = action.payload;
    yield put(actions.setUploadStatus('running'));
    if (!!attachments.length) {
      yield all(
        attachments.map(payload =>
          postEquipmentResource({
            customerId,
            locationId,
            equipmentId,
            payload,
          }),
        ),
      );
    }
    yield put(actions.setUploadStatus('success'));
    yield put(actions.getDocumentsAndCertificates());
  } catch (error) {
    yield put(actions.setUploadStatus('failed'));
  }
}

export function* equipmentAttachmentsSaga() {
  yield takeLatest(
    actions.getDocumentsAndCertificates.type,
    getDocumentsAndCertificates,
  );
  yield takeLatest(
    actions.editDocumentsAndCertificates,
    editDocumentsAndCertificates,
  );
  yield takeLatest(
    actions.deleteDocumentsAndCertificates,
    deleteDocumentsAndCertificates,
  );
  yield takeLatest(actions.uploadAttachments, uploadAttachments);
  yield takeLatest(actions.getResourceTypes, getResourceTypes);
}
