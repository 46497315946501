import { PayloadAction } from '@reduxjs/toolkit';

import { TaskTypeMetadata } from 'types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { DataTableSort } from 'app/components/DataTable/types';
import { EquipmentInspectionState, InspectionPayload } from './types';

export const initialState: EquipmentInspectionState = {
  inspections: {
    loading: false,
    error: undefined,
    data: undefined,
    sort: undefined,
    pageInfo: {
      pageSize: 25,
      page: 1,
      pageCount: undefined,
      totalRecords: undefined,
    },
  },
  isAdding: false,
  addingStatus: undefined,
  metadata: {
    data: undefined,
    loading: false,
    error: undefined,
  },
};

const inspectionsSlice = createSlice({
  name: 'equipmentInspection',
  initialState,
  reducers: {
    getInspections(state) {
      return {
        ...state,
        inspections: {
          ...state.inspections,
          loading: true,
        },
      };
    },
    getInspectionsSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        inspections: {
          ...state.inspections,
          loading: initialState.inspections.loading,
          data: action.payload.content,
          error: initialState.inspections.error,
          pageInfo: {
            ...state.inspections.pageInfo,
            pageSize: action.payload.size,
            pageCount: action.payload.totalPages,
            totalRecords: action.payload.numberOfElements,
          },
        },
      };
    },
    getInspectionsError(state, action: PayloadAction<any>) {
      return {
        ...state,
        inspections: {
          ...state.inspections,
          error: action.payload,
        },
      };
    },
    setInspectionsSort(state, action: PayloadAction<DataTableSort>) {
      return {
        ...state,
        inspections: {
          ...state.inspections,
          sort: action.payload,
          loading: true,
        },
      };
    },
    setInspectionsPageInfo(
      state,
      action: PayloadAction<{
        page: number;
        pageSize: number;
      }>,
    ) {
      return {
        ...state,
        inspections: {
          ...state.inspections,
          loading: true,
          pageInfo: {
            ...state.inspections.pageInfo,
            page: action.payload.page,
            pageSize: action.payload.pageSize,
          },
        },
      };
    },
    setIsAdding(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isAdding: action.payload,
        addingStatus: initialState.addingStatus,
      };
    },
    addInspection(state, action: PayloadAction<InspectionPayload>) {
      return {
        ...state,
        addingStatus: 'running',
      };
    },
    addInspectionSuccess(state) {
      return {
        ...state,
        addingStatus: 'success',
      };
    },
    addInspectionFailed(state) {
      return {
        ...state,
        addingStatus: 'failed',
      };
    },

    getMetadata(state) {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: true,
          error: initialState.metadata.error,
        },
      };
    },
    getMetadataSuccess(state, action: PayloadAction<TaskTypeMetadata[]>) {
      return {
        ...state,
        metadata: {
          data: action.payload,
          loading: initialState.metadata.loading,
          error: initialState.metadata.error,
        },
      };
    },
    getMetadataSkipped(state) {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: initialState.metadata.loading,
          error: initialState.metadata.error,
        },
      };
    },
    getMetadataError(state, action: PayloadAction<string>) {
      return {
        ...state,
        metadata: {
          data: initialState.metadata.data,
          loading: initialState.metadata.loading,
          error: action.payload,
        },
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = inspectionsSlice;
