import React, { useState } from 'react';
import { CalendarContainer } from 'react-datepicker';
import styled from 'styled-components/macro';
import {
  getMonth,
  getYear,
  format,
  parse,
  isValid,
  isAfter,
  isBefore,
  isSameDay,
} from 'date-fns';
import _range from 'lodash/range';
import { Icon } from '@eriksdigital/atomic-ui/components';
import {
  SideArrowIcon,
  BackArrowIcon,
  // CrossIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import 'react-datepicker/dist/react-datepicker.css';

import t, { translationString } from 'locales/translation';
import { Div } from 'app/components/Div';
import { Calendar } from 'app/components/Icons';
import { BgColor, TextColor } from 'types';

const years = _range(getYear(new Date()) - 10, getYear(new Date()) + 11, 1);

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

interface DateInputProps {
  type: 'start' | 'end';
  label: string;
  date: Date | null;
  otherDate: Date | null;
  handleChange: (date: Date | null) => void;
}

const DateInput: React.FC<DateInputProps> = ({
  type,
  label,
  date,
  otherDate,
  handleChange,
}) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [dateString, setDateString] = useState<string>(
    date ? format(date, 'dd-MM-yyyy') : '',
  );

  const handleDateChange = () => {
    const dateObject = parse(dateString, 'dd-MM-yyyy', new Date());
    const isDateValid = isValid(dateObject);

    let isValidDateCheck = true;

    if (isDateValid && otherDate !== null) {
      if (type === 'start') {
        isValidDateCheck =
          !isAfter(dateObject, otherDate) && !isSameDay(dateObject, otherDate);
      } else if (type === 'end') {
        isValidDateCheck =
          !isBefore(dateObject, otherDate) && !isSameDay(dateObject, otherDate);
      }
    }

    if (isDateValid && isValidDateCheck) {
      handleChange(dateObject);
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  return (
    <div>
      <Div display="flex" color={TextColor.secondaryNew}>
        {t(label)}
      </Div>

      <StyledInputWrapper
        display="flex"
        align="center"
        bg={BgColor.lighterGray}
        padding="12px 8px"
        mr="12px"
        error={hasError}
      >
        <Calendar />
        <input
          type="text"
          placeholder="dd-mm-yyyy"
          value={dateString}
          onChange={e => setDateString(e.target.value)}
          onBlur={handleDateChange}
          onKeyDown={e => {
            if (e.key === 'Enter') handleDateChange();
          }}
        />

        {/* TODO: Discuss the edge cases  */}
        {/* <Icon as={CrossIcon} size="sz16" onClick={() => handleClear()} /> */}
      </StyledInputWrapper>
    </div>
  );
};

export const CustomCalendarContainer = ({
  children,
  selectsRange,
  startDate,
  endDate,
  onChange,
}) => {
  return (
    <Container
      display="inline-block"
      padding="16px"
      bg={BgColor.white}
      borderRad="4px"
    >
      <CalendarContainer>
        {selectsRange && (
          <Div display="flex" mb="12px">
            <DateInput
              type="start"
              label="DateRange.From"
              date={startDate}
              otherDate={endDate}
              handleChange={(date: Date | null) => onChange([date, endDate])}
            />

            <DateInput
              type="end"
              label="DateRange.To"
              date={endDate}
              otherDate={startDate}
              handleChange={(date: Date | null) => onChange([startDate, date])}
            />
          </Div>
        )}

        <Div display="flex" justifyContent="center">
          {children}
        </Div>
      </CalendarContainer>
    </Container>
  );
};

export const renderCustomHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <CustomHeader display="flex" justifyContent="space-between" mb="14px">
      <Div cursor="pointer">
        <Icon
          size="sz16"
          as={BackArrowIcon}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        />

        <span>{translationString(`Month.${months[getMonth(date)]}`)}</span>

        <Icon
          size="sz16"
          as={SideArrowIcon}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        />
      </Div>

      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </CustomHeader>
  );
};

const Container = styled(Div)`
  font-size: 14px;
  box-shadow: 0px 0px 10px 0px rgba(22, 22, 22, 0.1);
`;

const CustomHeader = styled(Div)`
  color: ${({ theme }) => theme.colors.text.secondary};

  span,
  select {
    background: ${({ theme }) => theme.colors.background.white};
    color: ${({ theme }) => theme.colors.text.secondary};
    border: none;
    cursor: pointer;
  }

  span {
    margin: 0 16px;
  }
`;

const StyledInputWrapper = styled(Div)<{ error: boolean }>`
  border: ${({ error }) => (error ? '1px solid red' : 'none')};

  input {
    width: 90px;
    background: #f2f4f8;
    color: ${({ theme }) => theme.colors.default.blue2};
    border: none;
    margin-left: 12px;
  }
  input:focus {
    outline: none;
  }

  svg:nth-of-type(2) {
    color: ${({ theme }) => theme.colors.signal.alert};
    cursor: pointer;
  }
`;
