import React from 'react';
import Field from './Field';
import Label from './Label';
import { SelectProps } from './Select';
import { Select } from './withValidation';

export default (props: SelectProps) => (
  <Field>
    <Label htmlFor={props.name} required={props.required}>
      {props['aria-label']}
    </Label>
    <Select {...props} id={props.id || props.name} />
  </Field>
);
